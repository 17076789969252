import React from 'react';
import './Support.scss';
import { IconContext } from 'react-icons';
import { BiPhone, BiPlusMedical, BiEnvelope, BiBug} from "react-icons/bi";
import Topbar from '../../components/Topbar/Topbar';
import Card from '../../components/Card/Card';
import Navbar from '../../components/Navbar/Navbar';


function Support() {

    const data = [
        {
            key: '1',
            icon: <BiPlusMedical/>,
            name: 'Asesoría',
            link: 'https://calendly.com/sergio-leal/soporte'
        },
        {
            key: '2',
            icon: <BiEnvelope/>,
            name: 'Correo',
            link: 'mailto:sergio.leal@vieci.com.co?subject=SOPORTE%20&'
        },
        {
            key: '3',
            icon: <BiPhone/>,
            name: 'Teléfono',
            link: 'tel:+573004523259'
        },
    ]
    return (

        <IconContext.Provider value = {{ color: '#666bde', size: '40px'  }}>
            <Topbar/>
            <div className="support">
                <div className='support--container'>
                    <h2>Soporte</h2>
                    <p>¿Necesitas ayuda? Pide una asesoría a nuestro equipo médico, envíanos un correo o llámanos para poder ayudarte</p>
                    <div className="support--container__card">
                        {
                            data.map((data => 
                                    <a href={data.link} key={data.key}>
                                          <Card 
                                            key={data.key}
                                            icon={data.icon}
                                            name={data.name}
                                    />
                                    </a>
                                ))
                        }
                    </div>
                    <Navbar/>
                </div>
            </div>
        </IconContext.Provider>
    )
}

export default Support
