import React from 'react';

function MonthsInput({ onChange, }) {
    return (
        <div className='input'>
            <p>Mes a reportar</p>
            <select name="" id="" onChange={onChange}>
                <option hidden defaultValue>Selecciona una opción</option>
                <option value='Enero'>Enero</option>
                <option value='Febrero'>Febrero</option>
                <option value='Marzo'>Marzo</option>
                <option value='Abril'>Abril</option>
                <option value='Mayo'>Mayo</option>
                <option value='Junio'>Junio</option>
                <option value='Julio'>Julio</option>
                <option value='Agosto'>Agosto</option>
                <option value='Septiembre'>Septiembre</option>
                <option value='Octubre'>Octubre</option>
                <option value='Noviembre'>Noviembre</option>
                <option value='Diciembre'>Diciembre</option>
            </select>
        </div>
    )
}

export default MonthsInput;