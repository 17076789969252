import React from 'react';
import { Link } from 'react-router-dom';
import './Error404.scss';
import MainLogo from '../../assets/brand/eye.png'
import Button from '../../components/Button/Button';

function Error404() {
    return (
        <div className='error-container'>
            <div className="error-container__image">
                <img src={MainLogo} alt=""/>
            </div>
            <h2>Error 404, Página no encontrada</h2>
            <h3>No se ha encontrado la página que buscas</h3>
            <p>Puedes volver a la página anterior, visitar la página de inicio o ponerte en contacto con nuestro equipo de asistencia técnica.</p>
            <br/>
            <Link to='/'>
                <Button
                    title='volver a inicio'
                    className='error-container__button'
                />
            </Link>
        </div>
    )
}

export default Error404
