import React from 'react';
import './ProfileCard.scss';
import { IconContext } from 'react-icons';



function ProfileCard({ icon, name }) {
    return (
        <IconContext.Provider value = {{ color: '#666bde', size: '30px'  }}>
            <div className='profile-card'>
            <div className="profile-card--container">
                <i>{icon}</i> 
                <p>{name}</p>
            </div> 
            </div>
        </IconContext.Provider>
    )
}

export default ProfileCard
