import React, { useState } from 'react';
import './Problem.scss';
import { app, db } from '../../firebase/firebaseConfig';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Topbar from '../../components/Topbar/Topbar';
import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Button/Button';
import Navbar from '../../components/Navbar/Navbar';

function Problem() {

    const history = useHistory();
    const [err, setErr] = useState('');
    const [errTxt, setErrTxt] = useState('');
 
    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('bugs').add({
            error: err,
            texto: errTxt,
        })
        .then(()=> {
            swal({
                title: '¡Exito!',
                text: 'Los datos han sido enviados exitosamente',
                icon: 'success',
                button: 'Seguir'
            });
        })
        .then(()=> {
            history.goBack();
        })
        .catch(()=>{
            alert('Se presento un error, vuelve a intentar')
        })
    };


    return (
        <>
        <Topbar/>
        <div className='problem'>
        <h2>Reportar Errores</h2>
        <h4>¿Donde se presento el error?</h4>
        <form onSubmit={handleSubmit}>
            <select className='antibioticos--container__select'   value={err} onChange={(e) => setErr(e.target.value)}>
                <option hidden defaultValue>Selecciona una opción</option>  
                <option value="Indicadores">Indicadores</option>
                <option value="Listas de chequeo">Listas de chequeo</option>
                <option value="Uso de antibióticos">Uso de antibióticos</option>
                <option value="Covid">Covid</option>
                <option value="Casos IAAS">Casos IAAS</option>
                <option value="Soporte">Soporte</option>
                <option value="Otro">Otro</option>
            </select>
            <TextArea
                label='Describe el error'
                name=''
                cols='30'
                rows='8'
                value={errTxt} 
                onChange={(e)=>setErrTxt(e.target.value)}
            />
            <div className="problem--btn">
                <Button
                    title='Enviar'
                />
            </div>
        </form>
        <Navbar/>
        </div>
        </>
    )
}

export default Problem
