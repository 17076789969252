import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './InsercionCateter.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';

function InsercionCateter() {


    //get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //Usehistory Hook 
    const history = useHistory();

    //set state for data
    const [name, setName] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [diagnosis, setDiagnosis] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('');
    const [location, setLocation] = useState('');
    const [indication, setIndication] = useState('');
    const [other, setOther] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [option4, setOption4] = useState('');
    const [option5, setOption5] = useState('');
    const [option6, setOption6] = useState('');
    const [option7, setOption7] = useState('');
    const [option8, setOption8] = useState('');
    const [option9, setOption9] = useState('');
    const [option10, setOption10] = useState('');
    const [option11, setOption11] = useState('');
    const [option12, setOption12] = useState('');
    const [option13, setOption13] = useState('');
    const [option14, setOption14] = useState('');
    const [option15, setOption15] = useState('');
    const [option16, setOption16] = useState('');
    const [option17, setOption17] = useState('');
    const [option18, setOption18] = useState('');
    const [option19, setOption19] = useState('');
    const [option20, setOption20] = useState('');
    const [option21, setOption21] = useState('');
    const [option22, setOption22] = useState('');
    const [name2, setName2] = useState('');
    const [name3, setName3] = useState('');

    //data 
    const data = [
        {
            key: '1',
            label: 'Nombre del paciente',
            type: 'text',
            value: name,
            onChange: (e) => setName(e.target.value)
        },
        {
            key: '1.5',
            label: 'Tipo de documento',
            type: 'text',
            value: idType,
            onChange: (e) => setIdType(e.target.value)
        },
        {
            key: '2',
            label: 'Número de documento',
            type: 'text',
            value: id,
            onChange: (e) => setId(e.target.value)
        },
        {
            key: '3',
            label: 'Diagnóstico',
            type: 'text',
            value: diagnosis,
            onChange: (e) => setDiagnosis(e.target.value)
        },
        {
            key: '4',
            label: 'Fecha de inserción',
            type: 'date',
            value: date,
            onChange: (e) => setDate(e.target.value)
        },
        {
            key: '5',
            label: 'Tipo de catéter',
            type: 'text',
            value: type,
            onChange: (e) => setType(e.target.value)
        },
        {
            key: '6',
            label: 'Ubicación del catéter',
            type: 'text',
            value: location,
            onChange: (e) => setLocation(e.target.value)
        },
    ]

    const options1 = [
        {
            key: '1',
            label:'1. Firmó consentimiento informado de procedimiento.',
            value: option1,
            onChange: (e) => setOption1(e.target.value)
        },
        {
            key: '2',
            label:'2. Se informo del procedimiento al paciente.',
            value: option2,
            onChange: (e) => setOption2(e.target.value)
        },
        {
            key: '3',
            label:'3. Verifico los elementos necesarios para el paso del catéter',
            value: option3,
            onChange: (e) => setOption3(e.target.value)
        },
        {
            key: '4',
            label:'Catéter central',
            value: option4,
            onChange: (e) => setOption4(e.target.value)
        },
        {
            key: '5',
            label:'Guantes estériles',
            value: option5,
            onChange: (e) => setOption5(e.target.value)
        },
        {
            key: '6',
            label:'Campos estériles para paso de catéter',
            value: option6,
            onChange: (e) => setOption6(e.target.value)
        },
        {
            key: '7',
            label:'Lidocaína 2 % s/e',
            value: option7,
            onChange: (e) => setOption7(e.target.value)
        },
        {
            key: '8',
            label:'Sutura',
            value: option8,
            onChange: (e) => setOption8(e.target.value)
        },
        {
            key: '9',
            label:'Apósito transparente',
            value: option9,
            onChange: (e) => setOption9(e.target.value)
        },
        {
            key: '10',
            label:'Antiséptico solución y jabón',
            value: option10,
            onChange: (e) => setOption10(e.target.value)
        },
        {
            key: '11',
            label:'4 paquetes de gasas',
            value: option11,
            onChange: (e) => setOption11(e.target.value)
        },
        {
            key: '12',
            label:'Equipo de venoclisis',
            value: option12,
            onChange: (e) => setOption12(e.target.value)
        },
        {
            key: '13',
            label:'Jeringa',
            value: option13,
            onChange: (e) => setOption13(e.target.value)
        },
        {
            key: '14',
            label:'SSN 0,9%.',
            value: option14,
            onChange: (e) => setOption14(e.target.value)
        },
    ]
    const options2 = [
        {
            key: '1',
            label:'4. Lavado de manos por parte de todo el grupo de trabajo.',
            value: option15,
            onChange: (e) => setOption15(e.target.value)
        },
        {
            key: '2',
            label:'6. Se utilizaron campos estériles.',
            value: option16,
            onChange: (e) => setOption16(e.target.value)
        },
        {
            key: '3',
            label:'7. Todo el grupo utilizo los elementos de protección personal',
            value: option17,
            onChange: (e) => setOption17(e.target.value)
        },
        {
            key: '4',
            label:'8. Responsable de la inserción utilizo gorro, tapabocas, bata estéril y guantes estériles',
            value: option18,
            onChange: (e) => setOption18(e.target.value)
        },
        {
            key: '5',
            label:'9. Se mantuvo durante todo el procedimiento las técnica estéril.',
            value: option19,
            onChange: (e) => setOption19(e.target.value)
        },
        {
            key: '6',
            label:'10. ¿Durante el procedimiento se controlo el tráfico del personal?.',
            value: option20,
            onChange: (e) => setOption20(e.target.value)
        },
        {
            key: '7',
            label:'11. Se protege el catéter con apósito transparente',
            value: option21,
            onChange: (e) => setOption21(e.target.value)
        },
        {
            key: '8',
            label:'12. Se marco adecuadamente el catéter fecha, responsable y cm de inserción',
            value: option22,
            onChange: (e) => setOption22(e.target.value)
        },
    ]

    //send data to firestore
    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.length === 0 || idType.length === 0 || id.length === 0 || diagnosis.length === 0 ||
            date.length === 0 || type.length === 0 || location.length === 0 || indication.length === 0
            || other.length === 0 || name2.length === 0 || name3.length === 0) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('Inserción del Catéter Central').add({
                currentUser: currentUser,
                currentDate: currentDate,
    
                name: name,
                idType: idType,
                id: id,
                diagnosis: diagnosis,
                date: date,
                type: type,
                location: location,
                indication: indication,
                other: other,
    
                option1: option1,
                option2: option2,
                option3: option3,
                option4: option4,
                option5: option5,
                option6: option6,
                option7: option7,
                option8: option8,
                option9: option9,
                option10: option10,
                option11: option11,
                option12: option12,
                option13: option13,
                option14: option14,
                option15: option15,
                option16: option16,
                option17: option17,
                option18: option18,
                option19: option19,
                option20: option20,
                option21: option21,
                option22: option22,
    
                name2: name2,
                name3: name3
            })
            .then(() => {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(function () {
                history.goBack();
           })
            .catch((error) => {
                console.log(error);
            })
        }
       
    }
   
    return (
        <div className='listas'>
            <Topbar/>
            <div className="listas--container">
                <h2>Inserción del Catéter Central</h2>
                <div className="list--container__input">
                    <form action="" onSubmit={handleSubmit}>
                        {
                            data.map(data => 
                                <Input
                                    key={data.key}
                                    label={data.label}
                                    type={data.type}
                                    value={data.value}
                                    onChange={data.onChange}
                                />
                            )
                        }
                        <h4>Indicación de inserción del catéter</h4>
                        <select className='antibioticos--container__select' name='indicacion' value={indication} onChange={(e)=>setIndication(e.target.value)}>
                            <option hidden defaultValue>Selecciona una opción</option>  
                            <option value="NTP">NTP</option>
                            <option value="Antibiótico">Antibiótico</option>
                            <option value="Monitoreo">Monitoreo</option>
                            <option value="Otro">Otro</option>
                        </select>
                        <Input 
                            label='Si escogiste otro, especifica'
                            type='text'
                            value={other}
                            onChange={(e)=> setOther(e.target.value)}
                        />
                        <h3>Inserción de catéter</h3>
                        <h3>Antes de la inserción</h3>
                        {
                            options1.map(options1 =>
                                <SelectInput
                                    key={options1.key}
                                    label={options1.label}
                                    value={options1.value}
                                    onChange={options1.onChange}
                                /> 
                                )
                        }
                        <h3>Durante la inserción</h3>
                        {
                            options2.map(options2 =>
                                <SelectInput
                                    key={options2.key}
                                    label={options2.label}
                                    value={options2.value}
                                    onChange={options2.onChange}
                                /> 
                                )
                        }
                        <Input 
                            label='Nombre de quien realiza el procedimiento'
                            type='text'
                            value={name2}
                            onChange={(e)=> setName2(e.target.value)}
                        />
                        <Input 
                            label='Nombre de quien asiste el procedimiento'
                            type='text'
                            value={name3}
                            onChange={(e)=> setName3(e.target.value)}
                        />
                        <div className="listas--container__btn">
                            <Button 
                                title='Enviar'
                            />
                        </div>
                    </form>
                </div>
                <Navbar/>
            </div>
        </div>
    )
}

export default InsercionCateter
