import React, { useState } from 'react';
import './Antibioticos.scss';
import { app, db } from '../../firebase/firebaseConfig';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Topbar from '../../components/Topbar/Topbar';
import Input from '../../components/Input/Input';
import SelectInput from '../../components/SelectInput/SelectInput';
import Button from '../../components/Button/Button';
import Navbar from '../../components/Navbar/Navbar';

function Antibioticos() {
    //get user from firebase
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //get history
    const history = useHistory();

    //manage state
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [room, setRoom] = useState('');
    const [diagnosis, setDiagnosis] = useState('');
    const [otherOption, setOtherOption] = useState('');
    const [antibiotic, setAntibiotic] =  useState('');
    const [action, setAction] =  useState('');
    const [antibioticChange, setAntibioticChange] = useState('');
    const [reason, setReason] = useState('');
    const [mistake, setMistake] = useState('');
    const [guide, setGuide] = useState('');
    const [suspect, setSuspect] = useState('');

    //data
    const data = [
        {
            key: '1',
            label: 'Fecha',
            type: 'date',
            value: date,
            onChange: function (e) 
            { 
                return setDate(e.target.value) 
            }

        }, 
        {
            key: '2',
            label: 'Nombre del Paciente',
            type: 'text',
            value: name,
            onChange: function (e) 
            { 
                return setName(e.target.value) 
            }
        }, 
        {
            key: '2.5',
            label: 'Tipo de documento',
            type: 'text',
            value: idType,
            onChange: function (e) 
            { 
                return setIdType(e.target.value) 
            }
        }, 
        {
            key: '3',
            label: 'Número de documento',
            type: 'number',
            value: id,
            onChange: function (e) 
            { 
                return setId(e.target.value) 
            }
        }, 
        {
            key: '4',
            label: 'Edad',
            type: 'number',
            value: age,
            onChange: function (e) 
            { 
                return setAge(e.target.value) 
            }
        }, 
        {
            key: '5',
            label: 'Habitación',
            type: 'text',
            value: room,
            onChange: function (e) 
            { 
                return setRoom(e.target.value) 
            }
        }, 
    ]

    const options = [
        {
            key: '1',
            label: 'Error diagnostico',
            value: mistake,
            onChange: (e)=>setMistake(e.target.value)
        },
        {
            key: '2',
            label: 'Cumplimiento guía práctica',
            value: guide,
            onChange: (e)=>setGuide(e.target.value)
        },
        {
            key: '3',
            label: 'Sospecha el caso de una IAAS',
            value: suspect,
            onChange: (e)=>setSuspect(e.target.value)
        },
    ]

// send data to firestore
 const handleSubmit = (e) => {
     e.preventDefault();
     if (name.length === 0 || age.length === 0 || idType.lenght === 0 || id.length === 0|| room.lenght === 0 || antibiotic.length === 0 ) {
        swal({
            title: 'Cuidado',
            text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos',
            icon: 'warning',
            button: 'Volver'
        });
     } else {
        db.collection('Uso de antibióticos').add({
            currentuser: currentUser,
            currentDate: currentDate,
   
            date: date,
            name: name,
            age: age,
            idType: idType,
            id: id,
            room: room,
            diagnosis: diagnosis,
            otherOption: otherOption,
            antibiotic: antibiotic,
            action: action,
            antibioticChange: antibioticChange,
            reason: reason,
            mistake: mistake,
            guide: guide,
            suspect: suspect
        })
        .then(() => {
           swal({
               title: '¡Exito!',
               text: 'Los datos han sido enviados exitosamente',
               icon: 'success',
               button: 'Seguir'
           });
        })
        .then(() => {
           history.goBack();
        })
        .catch((err) => {
            console.log(err)
        })
     }
}

    return (
        <div className='antibioticos'>
            <Topbar/>
                <h2>Uso de Antibióticos</h2>
            <div className="antibioticos--container">
                <form action="" onSubmit={handleSubmit}>
                    {
                        data.map(data => 
                            <Input
                                key={data.key}
                                label={data.label}
                                type={data.type}
                                value={data.value}
                                onChange={data.onChange}
                                required
                            />)
                    }
                    <h4>Diagnóstico</h4>
                    <select className='antibioticos--container__select' value={diagnosis} onChange={(e)=> setDiagnosis(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="IVU">IVU</option>
                        <option value="IVU Nosocomial">IVU Nosocomial</option>
                        <option value="Neumonia">Neumonia</option>
                        <option value="Infección tracto respiratorio superior">Infección tracto respiratorio superior</option>
                        <option value="Neumonia nosocomial">Neumonia nosocomial</option>
                        <option value="Infección tracto respiratorio superior">Infección tracto respiratorio superior</option>
                        <option value="ISO">ISO</option>
                        <option value="Abdomen (Peritonitis, abseso)">Abdomen (Peritonitis, abseso)</option>
                        <option value="Infección tejidos blandos / piel, hueso">Infección tejidos blandos / piel, hueso</option>
                        <option value="Tracto gastrointestinal">Tracto gastrointestinal</option>
                        <option value="Infección tejidos blandos / piel, hueso">Infección tejidos blandos / piel, hueso</option>
                        <option value="Infección torrente sanguineo / bacteriana">Infección torrente sanguineo / bacteriana</option>
                        <option value="Bacteriana asociada a catéter">Bacteriana asociada a catéter</option>
                        <option value="Infeccion del sistema NC">Infeccion del sistema NC </option>
                        <option value="HIV">HIV</option>
                        <option value="HIV">TBC</option>
                        <option value="HIV">Diagnóstico no infeccioso</option>
                        <option value="HIV">Neutropenia Febríl</option>
                        <option value="HIV">Flebitis séptica</option>
                        <option value="HIV">SARS COV 2</option>
                        <option value="HIV">Otro</option>
                    </select>
                    <Input
                        label='Si escogiste otro, especifica'
                        type='text'
                        value={otherOption}
                        onChange={(e)=> setOtherOption(e.target.value)}
                    />
                    <Input
                        label='Antibiótico'
                        type='text'
                        value={antibiotic}
                        onChange={(e)=> setAntibiotic(e.target.value)}
                    />       
                     <h4>Acción del antibiótico</h4>
                    <select className='antibioticos--container__select' value={action} onChange={(e)=> setAction(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Cambio de dosis">Cambio de dosis</option>
                        <option value="Cambio de antibiótico">Cambio de antibiótico</option>
                        <option value="Inicia">Inicia</option>
                        <option value="Continua">Continua</option>
                        <option value="Finaliza">Finaliza</option>
                        <option value="No aplica">No aplica</option>
                    </select>
                  
                    <Input
                        label='¿Cuál antibiótico cambio?'
                        type='text'
                        value={antibioticChange}
                        onChange={(e)=> setAntibioticChange(e.target.value)}
                    /> 
                    <h4>Motivo del cambio</h4>
                    <select className='antibioticos--container__select' value={reason} onChange={(e)=>setReason(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Combinación no apropiada">Combinación no apropiada</option>
                        <option value="Costo/Efectividad">Costo/Efectividad</option>
                        <option value="Daño colateral">Daño colateral</option>
                        <option value="Diagnóstico no infeccioso">Diagnóstico no infeccioso</option>
                        <option value="Dificultad en la administración">Dificultad en la administración</option>
                        <option value="Dosis no apropiada">Dosis no apropiada</option>
                        <option value="Espectro no apropiado">Espectro no apropiado</option>
                        <option value="Extensión del tratamiento">Extensión del tratamiento</option>
                        <option value="Extensión no indicada">Extensión no indicada</option>
                        <option value="Farmacocinetica no favorable">Farmacocinetica no favorable</option>
                        <option value="Posible efecto adverso">Posible efecto adverso</option>
                        <option value="Primera elección no elegida">Primera elección no elegida</option>
                        <option value="No efectivo empiricamente">No efectivo empiricamente</option>
                        <option value="No aplica">No aplica </option>
                    </select>   
                    {
                        options.map(options => 
                            <SelectInput
                                key={options.key}
                                label={options.label}
                                value={options.value}
                                onChange={options.onChange}
                            />
                            
                        )
                    }
                <div className="antibioticos--container__btn">
                    <Button
                        title='Enviar'
                    />
                </div>
                </form>
            </div>
            <Navbar/>
        </div>
    )
}

export default Antibioticos
