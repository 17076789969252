import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserProvider } from '../providers/UserProvider';
import PrivateRoute from '../providers/PrivateRoute';
import Login from '../Pages/Login/Login';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Profile from '../Pages/Profile/Profile';
import Support from '../Pages/Support/Support';
import Indicadores from '../Pages/Indicadores/Indicadores';
import Uci from '../Pages/Indicadores/Uci/Uci';
import Cirugia from '../Pages/Indicadores/Cirugia/Cirugia';
import Neonatal from '../Pages/Indicadores/Neonatal/Neonatal';
import Hospitalizacion from '../Pages/Indicadores/Hospitalizacion/Hospitalizacion';
import Listas from '../Pages/Listas/Listas';
import Aislamiento from '../Pages/Listas/Aislamiento/Aislamiento';
import Nav from '../Pages/Listas/Nav/Nav';
import SeguimientoCateter from '../Pages/Listas/SeguimientoCateter/SeguimientoCateter';
import Curacion from '../Pages/Listas/Curacion/Curacion';
import IstuAc from '../Pages/Listas/IstuAc/IstuAc';
import IstuSeguimiento from '../Pages/Listas/IstuSeguimiento/IstuSeguimiento';
import Periferico from '../Pages/Listas/Periferico/Periferico';
import Seguimiento from '../Pages/Listas/Seguimiento/Seguimiento';
import ProtocoloAislamiento from '../Pages/Listas/ProtocoloAislamiento/ProtocoloAislamiento';
import ManejoCateter from '../Pages/Listas/ManejoCateter/ManejoCateter';
import InsercionCateter from '../Pages/Listas/InsercionCateter/InsercionCateter';
import Antibioticos from '../Pages/Antibioticos/Antibioticos';
import MedidasAislamiento from '../Pages/Listas/MedidasAislamiento/MedidasAislamiento';
import LavadoManos from '../Pages/Listas/LavadoManos/LavadoManos';
import Covid from '../Pages/Covid/Covid';
import Casos from '../Pages/Casos/Casos';
import Problem from '../Pages/Problem/Problem';
import Error404 from '../Pages/Error404/Error404';


function App() {
  return (
  <UserProvider>
    <Router>
      <Switch>
        <Route exact path='/' component={Login} /> 
        <PrivateRoute exact path='/dashboard' component={Dashboard}/> 
        <PrivateRoute exact path='/soporte' component={Support}/> 
        <PrivateRoute exact path='/perfil' component={Profile}/> 
        <PrivateRoute exact path='/form'><Indicadores name='Áreas'/> </PrivateRoute> 
          <PrivateRoute exact path='/uci' component={Uci}/> 
          <PrivateRoute exact path='/cirugia' component={Cirugia}/> 
          <PrivateRoute exact path='/hospitalizacion' component={Hospitalizacion}/>
          <PrivateRoute exact path='/neonatal' component={Neonatal}/>
        <PrivateRoute exact path='/listas-de-chequeo' component={Listas}/> 
          <PrivateRoute exact path='/aislamiento' component={Aislamiento} />
          <PrivateRoute exact path='/nav' component={Nav} />
          <PrivateRoute exact path='/seguimiento-cateter' component={SeguimientoCateter} />
          <PrivateRoute exact path='/curacion' component={Curacion} />
          <PrivateRoute exact path='/istu-ac' component={IstuAc} />
          <PrivateRoute exact path='/istu-seguimiento' component={IstuSeguimiento} />
          <PrivateRoute exact path='/periferico' component={Periferico} />
          <PrivateRoute exact path='/seguimiento' component={Seguimiento} />
          <PrivateRoute exact path='/protocolo-aislamiento' component={ProtocoloAislamiento} />
          <PrivateRoute exact path='/manejo-cateter' component={ManejoCateter} />
          <PrivateRoute exact path='/insercion-cateter' component={InsercionCateter} />
          <PrivateRoute exact path='/medidas-aislamiento' component={MedidasAislamiento} />
          <PrivateRoute exact path='/lavado-manos' component={LavadoManos} />
        <PrivateRoute exact path='/antibioticos' component={Antibioticos} />
        <PrivateRoute exact path='/covid' component={Covid}/>
        <PrivateRoute exact path='/casos' component={Casos}/>
        <PrivateRoute exact path='/reportar-problema' component={Problem}/>
        <Route component={Error404} /> 
      </Switch>
    </Router>
  </UserProvider>
  );
}

export default App;
