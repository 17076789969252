import React from 'react';
import './MenuCard.scss';

function MenuCard({ name }) {
    return (
        <div className='menu-card'>
            <div className="menu-card--container">
                <p>{name}</p>
            </div> 
        </div>
    )
}

export default MenuCard
