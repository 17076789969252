import React, { useState } from 'react';
import './Nav.scss';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { app, db } from '../../../firebase/firebaseConfig';
import Topbar from '../../../components/Topbar/Topbar';
import IdInput from '../../../components/IdInput/IdInput';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';


function Nav() {

    //get user and date
    const currentUser = app.auth().currentUser.email;
    const CurrentDate = new Date();

    //use History hook
    const history = useHistory();

    //set state for a data
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [nav1, setNav1] = useState('');
    const [nav2, setNav2] = useState('');
    const [nav3, setNav3] = useState('');
    const [nav4, setNav4] = useState('');

    //Data
    const data = [
        {
            key: '1',
            label:'1. Los equipos de oxigenoterapia están rotulados adecuadamente y vigentes.',
            value: nav1,
            onChange: (e) => setNav1(e.target.value)
        },
        {
            key: '2',
            label:'2. El paciente y familia recibieron educación de prevención (Alimentación, posición, estado de conciencia, Higiene bucal como y cada cuanto, lavado de manos).',
            value: nav2,
            onChange: (e) => setNav2(e.target.value)
        },
        {
            key: '3',
            label:'3. Paciente se le realiza higiene bucal 3 veces al día.',
            value: nav3,
            onChange: (e) => setNav3(e.target.value)
        },
        {
            key: '4',
            label:'4. Realizan verificación de que no se presente fuga del tubo orotraqueal.',
            value: nav4,
            onChange: (e) => setNav4(e.target.value)
        },
    ]

    //send data to firestore

    const handleSubmit = (e) => {
        e.preventDefault();
        if(id.length === 0 || idType.length === 0 || name.length === 0 || date.length === 0){
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('Nav').add({
                currentUser: currentUser,
                CurrentDate: CurrentDate,
                date: date,
                name: name,
                idType: idType,
                id: id,
                nav1: nav1,
                nav2: nav2,
                nav3: nav3,
                nav4: nav4
            })
            .then(() => {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(() => {
                history.goBack();
            })
            .catch((error)=> {
                console.log(error)
            })
        }
    }



    return (
        <div className='Nav'>
            <Topbar />
            <h2>Lista de chequeo de seguimiento diario a pacientes con ventilación mecánica invasiva</h2>
            <div className="Nav--container">
                <form action="" onSubmit={handleSubmit}>
                    <Input
                        label='Fecha'
                        type='date'
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                        required
                    /> 
                    <Input
                        label='Nombre del paciente'
                        type='text'
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                        required
                    /> 
                    <IdInput
                        value={idType}
                        onChange={(e) => setIdType(e.target.value)}
                        required
                    />
                    <Input
                        label='Número de identificación'
                        type='text'
                        value={id}
                        onChange={(e)=>setId(e.target.value)}
                        required
                    /> 
                    {
                        data.map( data => 
                                <SelectInput 
                                    key={data.key}
                                    label={data.label}
                                    value={data.value}
                                    onChange={data.onChange}
                                    required
                                />
                            )
                    }

                    <div className="Nav--container__btn">
                        <Button
                            title='Enviar'
                        /> 
                    </div>
                </form>
            </div>
            <Navbar/>
        </div>
    )
}

export default Nav
