import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Aislamiento.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import IdInput from '../../../components/IdInput/IdInput';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';


function Aislamiento() {


    //get user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();

    //useHistory Hook
    const history = useHistory();

    //set state for data
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [aislamiento1, setAislamiento1] = useState('');
    const [aislamiento2, setAislamiento2] = useState('');
    const [aislamiento3, setAislamiento3] = useState('');
    const [aislamiento4, setAislamiento4] = useState('');
    const [aislamiento5, setAislamiento5] = useState('');
    const [aislamiento6, setAislamiento6] = useState('');
    const [aislamiento7, setAislamiento7] = useState('');
    const [aislamiento8, setAislamiento8] = useState('');
    const [aislamiento9, setAislamiento9] = useState('');
    const [aislamiento10, setAislamiento10] = useState('');


    //data
    const data = [
        {
            key: '1',
            label: '1. El aislamiento implementado es pertinente.',
            value: aislamiento1,
            onChange: (e) => setAislamiento1(e.target.value)
        },
        {
            key: '2',
            label: '2. Afiche adecuado segun tipo de aislamiento.',
            value: aislamiento2,
            onChange: (e) => setAislamiento2(e.target.value)
        },
        {
            key: '3',
            label: '3. Registro correcto en el kardex del tipo de aislamiento',
            value: aislamiento3,
            onChange: (e) => setAislamiento3(e.target.value)
        },
        {
            key: '4',
            label: '4. Realiza higiene de manos en los 5 momentos OMS',
            value: aislamiento4,
            onChange: (e) => setAislamiento4(e.target.value)
        },
        {
            key: '5',
            label: '5. El personal asistencial usa EPP según tipos de aislamiento.',
            value: aislamiento5,
            onChange: (e) => setAislamiento5(e.target.value)
        },
        {
            key: '6',
            label: '6. La habitación dispone de tres batas antifluido,colgadas,rotuladas en adecuadas condiciones para utilizacion del personal y del acompañante o familiar y se estan usando dentro de la habitacion',
            value: aislamiento6,
            onChange: (e) => setAislamiento6(e.target.value)
        },
        {
            key: '7',
            label: '7. El personal que ingresa usa mascarilla quirurgica o de alta eficiencia de acuerdo al tipo de aislamiento.',
            value: aislamiento7,
            onChange: (e) => setAislamiento7(e.target.value)
        },
        {
            key: '8',
            label: '8. El personal que ingresa a la habitacion usa guantes al tener contacto con el paciente y superficies contaminadas.',
            value: aislamiento8,
            onChange: (e) => setAislamiento8(e.target.value)
        },
        {
            key: '9',
            label: '9. El paciente y/o su familia recibieron educacion sobre el aislamiento en el turno.',
            value: aislamiento9,
            onChange: (e) => setAislamiento9(e.target.value)
        },
        {
            key: '10',
            label: `10. Si el aislamiento es tipo cohortizacion se cumple las normas relacionadas: 

            a. Habitación unipersonal o bipersonal segun perfil de resistencia. 
            b. Utiliza material exclusivo (termómetro, bata, fonendoscopio, tensiómetro).  
            c. Realiza limpieza y desinfección antes y después de utilizar los equipo biomédico.`,
            value: aislamiento10,
            onChange: (e) => setAislamiento10(e.target.value)
        }  
    ]

    // send data to firestore
    const handleSubmit = (e) => {
        e.preventDefault();
        if (date.length === 0 || name.length === 0 || 
            idType.length === 0 || id.length === 0 ) {
                swal({
                    title: 'Cuidado',
                    text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                    icon: 'warning',
                    button: 'Volver'
                });
        } else {
            db.collection('Aislamiento').add({
                currentUser: currentUser,
                currentDate: currentDate,
                date: date,
                name: name,
                idType: idType,
                id: id,
                aislamiento1: aislamiento1,
                aislamiento2: aislamiento2,
                aislamiento3: aislamiento3,
                aislamiento4: aislamiento4,
                aislamiento5: aislamiento5,
                aislamiento6: aislamiento6,
                aislamiento7: aislamiento7,
                aislamiento8: aislamiento8,
                aislamiento9: aislamiento9,
                aislamiento10: aislamiento10,
            })
            .then(() => {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(() => {
                history.goBack();
            })
            .catch((error) => {
                console.log(error)
            })
        }
    };

    return (
        <div className='Aislamiento'>
            <Topbar/>
            <h2>Aislamiento</h2>
            <div className='Aislamiento--container'>
                <form action="" onSubmit={handleSubmit}>    
                    <Input 
                           label='Fecha'
                           type='date'
                           value={date}
                           onChange={(e)=>setDate(e.target.value)}
                    />
                    <Input 
                           label='Nombre paciente'
                           type='text'
                           value={name}
                           onChange={(e)=>setName(e.target.value)}
                    />
                    <IdInput
                        value={idType}
                        onChange={(e) => setIdType(e.target.value)}
                    />
                    <Input 
                           label='Número de identificación'
                           type='text'
                           value={id}
                           onChange={(e)=>setId(e.target.value)}
                    />
                  {
                      data.map( data => 
                            <SelectInput
                                key={data.key}
                                label={data.label}
                                value={data.value}
                                onChange={data.onChange}
                            />
                        )
                  }
                <div className="Aislamiento--container__btn">
                    <Button
                        title='Enviar'
                    />
                </div>
                </form>
            </div>
            <Navbar/>
        </div>
    )
}

export default Aislamiento
