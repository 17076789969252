import React from 'react';
import './Dashboard.scss';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { BiCheck, BiUser,BiSupport, BiBuilding, BiBug } from "react-icons/bi";
import { RiMedicineBottleLine } from "react-icons/ri";
import { RiVirusFill } from "react-icons/ri";
import Topbar from '../../components/Topbar/Topbar';
import Card from '../../components/Card/Card';
import Navbar from '../../components/Navbar/Navbar';

function Dashboard() {
    
    const data = [
        {
            key: '1',
            path: '/form',
            name: 'Indicadores',
            icon: <BiBuilding/>,
        },
        {
            key: '2',
            path: '/listas-de-chequeo',
            name: 'Listas',
            icon: <BiCheck/>,

        },
        {
            key: '3',
            path: '/antibioticos',
            name: 'Antibióticos',
            icon: <RiMedicineBottleLine/>,

        },
        {
            key: '4',
            path: '/soporte',
            name: 'Soporte',
            icon: <BiSupport/>
        },
        {
            key: '5',
            path: '/reportar-problema',
            name: 'Problema',
            icon: <BiBug/>
        }
    ]

   

    return (
        <IconContext.Provider value = {{ color: '#666bde', size: '40px'  }}>
            <Topbar/>
            <h2>Dashboard</h2>
         <div className='dashboard--container'>
        <div className="dashboard--container__card">  
                {
                    data.map((data => 
                        <Link to={data.path}  key={data.key} style={{textDecoration: 'none', color: '#333'}} >
                            <Card
                            key={data.key}
                            icon={data.icon}
                            name={data.name}
                        />
                        </Link>
                        ))
                }

            </div>
        </div>
            <Navbar/> 
        </IconContext.Provider>
    )
}

export default Dashboard
