import React from 'react';
import './Indicadores.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/Topbar/Topbar';
import MenuCard from '../../components/MenuCard/MenuCard';
import Navbar from '../../components/Navbar/Navbar';

function Indicadores({name}) {
    //data of each floor
    const piso1 = [
        {
            key: '1',
            name: 'Hospitalización',
            path: '/hospitalizacion',
        },
        {
            key: '2',
            name: 'Uci Tercer Piso',
            path: '/uci'
        },
        {
            key: '3',
            name: 'UCI Cuarto Piso',
            path: '/cirugia'
        },

    ];
    
   return (
        <div className='page--container'>
            <Topbar/>
            <h2>{name}</h2>
            <div className="page--container__card">
                <h3>Indicadores</h3>
            {
                piso1.map((piso1 => 
                    <Link to={piso1.path}  key={piso1.key} style={{textDecoration: 'none', color: '#333'}} >
                       <MenuCard
                            key={piso1.key}
                            name={piso1.name}
                        /> 
                    </Link>
                        ))
            }
            </div>
            <Navbar/>
            
        </div>
    )
}

export default Indicadores
