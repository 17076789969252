import React from 'react';
import './Input.scss';

function Input({ label, type, placeholder, value, name, onChange, className }) {
    return (
        <div className='input-container'>
            <p>{label}</p>
            <input type={type}
                   placeholder={placeholder} 
                   name={name}
                   value={value}
                   onChange={onChange}
                   className={className}
            />
        </div>
    )
}

export default Input
