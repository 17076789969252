import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ManejoCateter.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import TextArea from '../../../components/TextArea/TextArea';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';


function ManejoCateter() {  
    //get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //useHistory Hook
    const history = useHistory();

    // set state for data
    const [name, setName] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [diagnostic, setDiagnostic] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('');
    const [location, setLocation] = useState('');
    const [service, setService] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [question6, setQuestion6] = useState('');
    const [question7, setQuestion7] = useState('');
    const [question8, setQuestion8] = useState('');
    const [question9, setQuestion9] = useState('');
    const [question10, setQuestion10] = useState('');
    const [question11, setQuestion11] = useState('');
    const [observation, setObservation] = useState('');
    // data
    const data = [
        {
            key:'1',
            label:'Nombre del paciente' ,
            type:'text',
            value: name,
            onChange: (e)=>setName(e.target.value)
        },
        {
            key:'1.5',
            label:'Tipo de Documento' ,
            type:'text',
            value: idType,
            onChange: (e)=>setIdType(e.target.value)
        },
        {
            key:'2',
            label:'Número de identificación' ,
            type:'text',
            value: id,
            onChange: (e)=>setId(e.target.value)
        },
        {
            key:'3',
            label:'Diágnostico' ,
            type:'text',
            value: diagnostic,
            onChange: (e)=>setDiagnostic(e.target.value)
        },
        {
            key:'4',
            label:'fecha de inserción' ,
            type:'date',
            value: date,
            onChange: (e)=>setDate(e.target.value)
        },
    ]

    const options = [
        {
            key:'1',
            label:' 1. Recibió capacitación en los últimos 6 meses en manejo e inserción de catéter',
            value: question1,
            onChange: (e)=>setQuestion1(e.target.value)
        },
        {
            key:'2',
            label:'2. Existe indicacion de cateter central',
            value: question2,
            onChange: (e)=>setQuestion2(e.target.value)
        },
        {
            key:'3',
            label:'3. Punto de inserción libre de signos de infección (Rubor, Calor o Secreción)',
            value: question3,
            onChange: (e)=>setQuestion3(e.target.value)
        },
        {
            key:'4',
            label:'4. El catéter cumple con los requerimientos de marcación (fecha de inserción y nombre)',
            value: question4,
            onChange: (e)=>setQuestion4(e.target.value)
        },
        {
            key:'5',
            label:'5.Mantiene una técnica estéril para realizar curación.',
            value: question5,
            onChange: (e)=>setQuestion5(e.target.value)
        },
        {
            key:'6',
            label:'6. Deja que el antiséptico seque al aire mínimo 1 minuto',
            value: question6,
            onChange: (e)=>setQuestion6(e.target.value)
        },
        {
            key:'7',
            label:'7. Usa gasas estériles y apósitos estériles, transparentes y semipermeables para cubrir el punto de inserción',
            value: question7,
            onChange: (e)=>setQuestion7(e.target.value)
        },
        {
            key:'8',
            label:'8. El apósito se encuentra visiblemente limpio, sin humedad y no aflojado',
            value: question8,
            onChange: (e)=>setQuestion8(e.target.value)
        },
        {
            key:'9',
            label:'9. Los lúmenes de los catéteres se encuentra con líquidos a permeabilidad.',
            value: question9,
            onChange: (e)=>setQuestion9(e.target.value)
        },
        {
            key:'10',
            label:'10. Se realiza curación pertinente a los 7 días.',
            value: question10,
            onChange: (e)=>setQuestion10(e.target.value)
        },
        {
            key:'11',
            label:'11. Los equipos de infusión se cambiaron oportunamente.',
            value: question11,
            onChange: (e)=>setQuestion11(e.target.value)
        },
    ]
    // send data to firestore
    const handleSubmit = (e) => {
        e.preventDefault();
        if(date.length === 0 || date.length === 0 || diagnostic.length === 0 || idType.length === 0 || 
            id.length === 0 || type.length === 0 || location.length === 0 || service.length === 0  ) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('Manejo de Catéter Central').add({
                currentUser: currentUser,
                currentDate: currentDate,
                name: name,
                date: date,
                diagnostic: diagnostic,
                idType: idType,
                id: id,
                type: type,
                location: location,
                service: service,
                question1: question1,
                question2: question2,
                question3: question3,
                question4: question4,
                question5: question5,
                question6: question6,
                question7: question7,
                question8: question8,
                question9: question9,
                question10: question10,
                question11: question11,
                observation: observation
            })
            // success
            .then(() => {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(function () {
                history.goBack();
           })
            // error
            .catch((error) => {
                console.log(console.error)
            })
        }
        
    }


    return (
        <div className='list'>
            <Topbar/>
            <div className="list--container">
                <h2>Manejo de Catéter Central</h2>
                <div className="list--container__input">
                <form action="" onSubmit={handleSubmit}>
                        { 
                            data.map(data => 
                                    <Input
                                        key={data.key}
                                        label={data.label}
                                        type={data.type}
                                        value={data.value}
                                        onChange={data.onChange}
                                    />
                                )
                        }
                    <h4>Tipo de cateter</h4>
                    <select className='antibioticos--container__select' name='Servicio'  value={type} onChange={(e)=>setType(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Catéter Central">Catéter Central</option>
                        <option value="Epicutaneo">Epicutaneo</option>
                        <option value="PICC">PICC</option>
                        <option value="Mahurkar">Mahurkar</option>
                        <option value="Otro">Otro</option>
                    </select>
                    <Input 
                        label='Ubicación del catéter'
                        type='Text'
                        value={location}
                        onChange={(e)=>setLocation(e.target.value)}
                    />
                    <h4>Servicio</h4>
                    <select className='antibioticos--container__select' name='Servicio'  value={service} onChange={(e)=>setService(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Uci Adultos">Uci Adultos</option>
                        <option value="Cirugía">Cirugía</option>
                        <option value="Hospitalización P1">Hospitalización P1</option>
                        <option value="Hospitalización P4">Hospitalización P4</option>
                        <option value="UCI Neonatal">UCI Neonatal</option>
                    </select>
                    
                    {
                        options.map(options => 
                                <SelectInput 
                                    key={options.key}
                                    label={options.label}
                                    value={options.value}
                                    onChange={options.onChange}
                                />
                            )
                    }
                    <TextArea
                        label='Observaciones'
                        type='text'
                        name='observaciones'
                        cols='30'
                        rows='8'
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                    />
                    <div className="list--container__btn">
                        <Button 
                            title='Enviar'
                        />
                    </div>
                </form>
                </div>
            <Navbar/>
            </div>
        </div>
    )
}

export default ManejoCateter
