import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ProtocoloAislamiento.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import IdInput from '../../../components/IdInput/IdInput';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';


function ProtocoloAislamiento() {

    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();

    //useHistory Hook
    const history = useHistory();

    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [uci1, setUci1] = useState('');
    const [uci2, setUci2] = useState('');
    const [uci3, setUci3] = useState('');
    const [surgery1, setSurgery1] = useState('');
    const [surgery2, setSurgery2] = useState('');
    const [surgery3, setSurgery3] = useState('');
    const [hosp1, setHosp1] = useState('');
    const [hosp2, setHosp2] = useState('');
    const [hosp3, setHosp3] = useState('');
    const [hosp4, setHosp4] = useState('');
    const [hosp5, setHosp5] = useState('');
    const [hosp6, setHosp6] = useState('');
    const [hosp7, setHosp7] = useState('');
    const [hosp8, setHosp8] = useState('');
    const [hosp9, setHosp9] = useState('');
    const [date2, setDate2] = useState('');
    const [name2, setName2] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if(date.length === 0 || name.length === 0 || idType.length === 0 || id.length === 0 || 
            uci1.length === 0 || uci2.length === 0 || uci3.length === 0 || surgery1.length === 0 
            ||surgery2.length === 0 || surgery3.length === 0 || hosp1.length === 0 || hosp2.length === 0 
            || hosp3.length === 0 || hosp4.length === 0 || hosp5.length === 0 || hosp6.length === 0 
            || hosp7.length === 0 || hosp8.length === 0 || hosp9.length === 0 || date2.length === 0 
            || name2.length === 0) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('Adherencia al Protocólo de Aislamiento').add({
                user: currentUser,
                currentDate: currentDate,
    
                date: date,
                name: name,
                idType: idType,
                id: id,
                uci1: uci1,
                uci2: uci2,
                uci3: uci3,
                surgery1: surgery1,
                surgery2: surgery2,
                surgery3: surgery3,
                hosp1: hosp1,
                hosp2: hosp2,
                hosp3: hosp3,
                hosp4: hosp4,
                hosp5: hosp5,
                hosp6: hosp6,
                hosp7: hosp7,
                hosp8: hosp8,
                hosp9: hosp9,
    
                date2: date2,
                name2:name2,
            })
            .then(()=> {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(function () {
                history.goBack();
           })
            .catch((error)=> {
                console.log(console.error())
            })
        }  
    };

    
    return (
        <div className='list'>
            <Topbar/>
            <div className="list--container">
                <h2>Adherencia al Protocólo de Aislamiento</h2>
                <div className="list--container__card">
                    <form action="" onSubmit={handleSubmit}>
                        <h4>Fecha</h4>
                        <Input
                            label=''
                            type='date'
                            value={date}
                            onChange={(e)=>setDate(e.target.value)}
                        /> 
                        <Input
                            label='Nombre paciente'
                            type='text'
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                        /> 
                        <IdInput
                            value={idType}
                            onChange={(e) => setIdType(e.target.value)}
                        />
                        <Input
                            label='Número de identificación'
                            type='text'
                            value={id}
                            onChange={(e)=>setId(e.target.value)}
                        /> 
                        <h3>UCI Adultos</h3>
                        <Input
                            label='Total de Pacientes'
                            type='number'
                            value={uci1}
                            onChange={(e)=>setUci1(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes que Requieren Aislamiento'
                            type='number'
                            value={uci2}
                            onChange={(e)=>setUci2(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes Aislados'
                            type='number'
                            value={uci3}
                            onChange={(e)=>setUci3(e.target.value)}
                        /> 
                        <h3>Cirugia</h3>
                        <Input
                            label='Total de Pacientes'
                            type='number'
                            value={surgery1}
                            onChange={(e)=>setSurgery1(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes que Requieren Aislamiento'
                            type='number'
                            value={surgery2}
                            onChange={(e)=>setSurgery2(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes Aislados'
                            type='number'
                            value={surgery3}
                            onChange={(e)=>setSurgery3(e.target.value)}
                        /> 
                        <h3>Hospitalización P1</h3>
                        <Input
                            label='Total de Pacientes'
                            type='number'
                            value={hosp1}
                            onChange={(e)=>setHosp1(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes que Requieren Aislamiento'
                            type='number'
                            value={hosp2}
                            onChange={(e)=>setHosp2(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes Aislados'
                            type='number'
                            value={hosp3}
                            onChange={(e)=>setHosp3(e.target.value)}
                        /> 
                        <h3>Hospitalización P4</h3>
                        <Input
                            label='Total de Pacientes'
                            type='number'
                            value={hosp4}
                            onChange={(e)=>setHosp4(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes que Requieren Aislamiento'
                            type='number'
                            value={hosp5}
                            onChange={(e)=>setHosp5(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes Aislados'
                            type='number'
                            value={hosp6}
                            onChange={(e)=>setHosp6(e.target.value)}
                        /> 
                        <h3>UCI Neonatal</h3>
                        <Input
                            label='Total de Pacientes'
                            type='number'
                            value={hosp7}
                            onChange={(e)=>setHosp7(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes que Requieren Aislamiento'
                            type='number'
                            value={hosp8}
                            onChange={(e)=>setHosp8(e.target.value)}
                        /> 
                        <Input
                            label='Total de Pacientes Aislados'
                            type='number'
                            value={hosp9}
                            onChange={(e)=>setHosp9(e.target.value)}
                        /> 
                        <Input
                            label='Fecha de seguimiento'
                            type='date'
                            value={date2}
                            onChange={(e)=>setDate2(e.target.value)}
                        /> 
                        <Input
                            label='Nombre Quien Realiza'
                            type='text'
                            value={name2}
                            onChange={(e)=>setName2(e.target.value)}
                        /> 
                        <div className="list--container__btn">
                            <Button
                                title='Enviar'
                            />
                        </div>
                    </form>
                </div>
            </div>
            <Navbar/>
        </div>
    )
}

export default ProtocoloAislamiento
