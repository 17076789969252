import React from 'react';
import './SelectInput.scss';

function SelectInput({ label, onChange }) {
    return (
        <div className='input-container'>
            <p>{label}</p>
            <select name="" id="" onChange={onChange}>
                <option hidden defaultValue>Selecciona una opción</option>
                <option value='Cumple'>Cumple</option>
                <option value='No Cumple'>No Cumple</option>
                <option value='No Aplica'>No Aplica</option>
            </select>
        </div>
    )
}

export default SelectInput
