import React, { useState, useEffect, createContext } from 'react';
import { app } from '../firebase/firebaseConfig';

export const UserContext = createContext()
export const UserProvider = ({ children }) => {
const [currentUser, setCurrentUser] = useState(null)

    useEffect((() => {
        app.auth().onAuthStateChanged(setCurrentUser)
    }), []);

    return (
        <UserContext.Provider
         value={(
            currentUser
        )}
        >
             {children}
        </UserContext.Provider>
    )
}