import React from 'react';
import './IdInput.scss';

function IdInput({ onChange, value }) {
    return (
        <div className='id'>
             <p>Tipo de Documento</p>
            <select name="" id="" onChange={onChange} value={value}>
                <option hidden defaultValue>Selecciona una opción</option>
                <option value='Registro Civil'>Registro Civil</option>
                <option value='Tarjeta de Identidad'>Tarjeta de Identidad</option>
                <option value='Cédula de ciudadanía'>Cédula de ciudadanía</option>
                <option value='Tarjeta de extranjería'>Tarjeta de extranjería</option>
                <option value='Cédula de extranjería '>Cédula de extranjería </option>
                <option value='Pasaporte '>Pasaporte </option>
                <option value='Tipo de documento extranjero'>Tipo de documento extranjero</option>
            </select> 
        </div>
    )
}

export default IdInput
