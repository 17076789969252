import React, { useState } from 'react';
import './LavadoManos.scss';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import CheckBox from '../../../components/CheckBox/CheckBox';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';

function LavadoManos() {

    const date = new Date();

    const [periodo, setPeriodo] = useState('');
    const [sesion, setSesion] = useState('');
    const [servicio, setServicio] = useState('');
    const [fecha, setFecha] = useState('');
    const [observador, setObservador] = useState('');
    const [sala, setSala] = useState('');
    const [inicio, setInicio]= useState('');
    const [fin, setFin]= useState('');
    const [duracion, setDuracion] = useState('')



    const data = [ 
        
    {
        key: '1',
        label: 'Periodo Número',
        type: 'text',
        value: periodo,
        onChange: (e) => setPeriodo(e.target.value)
    },
    {
        key: '2',
        label: 'Sesión Número',
        type: 'text',
        value: sesion,
        onChange: (e) => setSesion(e.target.value)
    },
    {
        key: '3',
        label: 'Servicio',
        type: 'text',
        value: servicio,
        onChange: (e) => setServicio(e.target.value)
    },
    {
        key: '4',
        label: 'Fecha',
        type: 'date',
        value: fecha,
        onChange: (e) => setFecha(e.target.value)
    },
    {
        key: '5',
        label: 'Observador',
        type: 'text',
        value: observador,
        onChange: (e) => setObservador(e.target.value)
    },
    {
        key: '6',
        label: 'Sala',
        type: 'text',
        value: sala,
        onChange: (e) => setSala(e.target.value)
    },
    {
        key: '7',
        label: 'Inicio',
        type: 'text',
        value: inicio,
        onChange: (e) => setInicio(e.target.value)
    },
]
       
    
    return (
        <>
        <Topbar/>
            <div className='lavado'>
                <h2>Higiene de manos</h2>
                <div className="lavado--container">
                    <form action="">
                        {
                            data.map(data => 
                                <Input 
                                    key={data.key}
                                    label={data.label}
                                    type={data.type}
                                    value={data.value}
                                    onChange={data.onChange}

                                />
                                )
                        }
                        <h4>Categoría del profesional</h4>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <optgroup label='Enfermera'>
                                    <option value="1.1 Enfermera">1.1 Enfermera</option>
                                    <option value="1.2 Estudiante ">1.2 Estudiante </option>
                                </optgroup>
                                <optgroup label='Auxiliar'>
                                    <option value="2.1 Auxiliar de enfermería">2.1 Auxiliar de enfermería</option>
                                </optgroup>
                                <optgroup label='Médico'>
                                    <option value="3.1 Internista y otros especialistas médicos ">3.1 Internista y otros especialistas médicos </option>
                                    <option value="3.2 Cirujano y otros especialistas quirúrgicos ">3.2 Cirujano y otros especialistas quirúrgicos </option>
                                    <option value="3.3 Anestesista/ reanimador / médico de urgencias">Anestesista/ reanimador / médico de urgencias</option>
                                    <option value="3.4 Pediatría">3.4 Pediatría</option>
                                    <option value="3.5 Ginecólogo">3.5 Ginecólogo</option>
                                    <option value="3.6 Medico de Consulta Externa">3.6 Medico de Consulta Externa</option>
                                    <option value="3.7 Estudiante de medicina">3.7 Estudiante de medicina</option>
                                </optgroup>
                                <optgroup label='otra categoría profesional'>
                                    <option value="4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)">4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)</option>
                                    <option value="4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)">4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)</option>
                                    <option value="4.3 Otros (nutricionista, odontólogo, trabajador">4.3 Otros (nutricionista, odontólogo, trabajador</option>
                                    <option value="4.4 Estudiante (no recogido en los anteriores)">4.4 Estudiante (no recogido en los anteriores)</option>
                                </optgroup>
                        </select>
                        <Input
                            label='Número'
                        /> 
                        <h4>Oportunidad 1</h4>
                        <h4>Indicación</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <CheckBox
                            label='Antes de tener contacto con el
                            paciente'
                        />
                        <CheckBox
                            label='Antes de realizar una tarea
                            limpia o escéptica'
                        />
                        <CheckBox
                            label=' Después de tener contacto
                            con fluidos corporales'
                        />
                        <CheckBox
                            label='después de tener contacto
                            con el paciente'
                        />
                        <CheckBox
                            label='Después de tener contacto
                            con el entorno del paciente'
                        />
                        <h4>HM Acción</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <option value="FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol">FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol</option>
                                <option value="LM: acción de HM que consiste en lavarse">las manos con agua y jabón</option>
                                <option value="Perdida: no se realiza ninguna acción de HM">Perdida: no se realiza ninguna acción de HM</option>
                        </select>
                        <h4>Categoría del profesional</h4>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <optgroup label='Enfermera'>
                                    <option value="1.1 Enfermera">1.1 Enfermera</option>
                                    <option value="1.2 Estudiante ">1.2 Estudiante </option>
                                </optgroup>
                                <optgroup label='Auxiliar'>
                                    <option value="2.1 Auxiliar de enfermería">2.1 Auxiliar de enfermería</option>
                                </optgroup>
                                <optgroup label='Médico'>
                                    <option value="3.1 Internista y otros especialistas médicos ">3.1 Internista y otros especialistas médicos </option>
                                    <option value="3.2 Cirujano y otros especialistas quirúrgicos ">3.2 Cirujano y otros especialistas quirúrgicos </option>
                                    <option value="3.3 Anestesista/ reanimador / médico de urgencias">Anestesista/ reanimador / médico de urgencias</option>
                                    <option value="3.4 Pediatría">3.4 Pediatría</option>
                                    <option value="3.5 Ginecólogo">3.5 Ginecólogo</option>
                                    <option value="3.6 Medico de Consulta Externa">3.6 Medico de Consulta Externa</option>
                                    <option value="3.7 Estudiante de medicina">3.7 Estudiante de medicina</option>
                                </optgroup>
                                <optgroup label='otra categoría profesional'>
                                    <option value="4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)">4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)</option>
                                    <option value="4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)">4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)</option>
                                    <option value="4.3 Otros (nutricionista, odontólogo, trabajador">4.3 Otros (nutricionista, odontólogo, trabajador</option>
                                    <option value="4.4 Estudiante (no recogido en los anteriores)">4.4 Estudiante (no recogido en los anteriores)</option>
                                </optgroup>
                        </select>
                        <Input
                            label='Número'
                        /> 
                        <h4>Oportunidad 2</h4>
                        <h4>Indicación</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <CheckBox
                            label='Antes de tener contacto con el
                            paciente'
                        />
                        <CheckBox
                            label='Antes de realizar una tarea
                            limpia o escéptica'
                        />
                        <CheckBox
                            label=' Después de tener contacto
                            con fluidos corporales'
                        />
                        <CheckBox
                            label='después de tener contacto
                            con el paciente'
                        />
                        <CheckBox
                            label='Después de tener contacto
                            con el entorno del paciente'
                        />
                        <h4>HM Acción</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <option value="FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol">FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol</option>
                                <option value="LM: acción de HM que consiste en lavarse">las manos con agua y jabón</option>
                                <option value="Perdida: no se realiza ninguna acción de HM">Perdida: no se realiza ninguna acción de HM</option>
                        </select>
                        <h4>Categoría del profesional</h4>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <optgroup label='Enfermera'>
                                    <option value="1.1 Enfermera">1.1 Enfermera</option>
                                    <option value="1.2 Estudiante ">1.2 Estudiante </option>
                                </optgroup>
                                <optgroup label='Auxiliar'>
                                    <option value="2.1 Auxiliar de enfermería">2.1 Auxiliar de enfermería</option>
                                </optgroup>
                                <optgroup label='Médico'>
                                    <option value="3.1 Internista y otros especialistas médicos ">3.1 Internista y otros especialistas médicos </option>
                                    <option value="3.2 Cirujano y otros especialistas quirúrgicos ">3.2 Cirujano y otros especialistas quirúrgicos </option>
                                    <option value="3.3 Anestesista/ reanimador / médico de urgencias">Anestesista/ reanimador / médico de urgencias</option>
                                    <option value="3.4 Pediatría">3.4 Pediatría</option>
                                    <option value="3.5 Ginecólogo">3.5 Ginecólogo</option>
                                    <option value="3.6 Medico de Consulta Externa">3.6 Medico de Consulta Externa</option>
                                    <option value="3.7 Estudiante de medicina">3.7 Estudiante de medicina</option>
                                </optgroup>
                                <optgroup label='otra categoría profesional'>
                                    <option value="4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)">4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)</option>
                                    <option value="4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)">4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)</option>
                                    <option value="4.3 Otros (nutricionista, odontólogo, trabajador">4.3 Otros (nutricionista, odontólogo, trabajador</option>
                                    <option value="4.4 Estudiante (no recogido en los anteriores)">4.4 Estudiante (no recogido en los anteriores)</option>
                                </optgroup>
                        </select>
                        <Input
                            label='Número'
                        /> 
                        <h4>Oportunidad 3</h4>
                        <h4>Indicación</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <CheckBox
                            label='Antes de tener contacto con el
                            paciente'
                        />
                        <CheckBox
                            label='Antes de realizar una tarea
                            limpia o escéptica'
                        />
                        <CheckBox
                            label=' Después de tener contacto
                            con fluidos corporales'
                        />
                        <CheckBox
                            label='después de tener contacto
                            con el paciente'
                        />
                        <CheckBox
                            label='Después de tener contacto
                            con el entorno del paciente'
                        />
                        <h4>HM Acción</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <option value="FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol">FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol</option>
                                <option value="LM: acción de HM que consiste en lavarse">las manos con agua y jabón</option>
                                <option value="Perdida: no se realiza ninguna acción de HM">Perdida: no se realiza ninguna acción de HM</option>
                        </select>
                        <h4>Categoría del profesional</h4>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <optgroup label='Enfermera'>
                                    <option value="1.1 Enfermera">1.1 Enfermera</option>
                                    <option value="1.2 Estudiante ">1.2 Estudiante </option>
                                </optgroup>
                                <optgroup label='Auxiliar'>
                                    <option value="2.1 Auxiliar de enfermería">2.1 Auxiliar de enfermería</option>
                                </optgroup>
                                <optgroup label='Médico'>
                                    <option value="3.1 Internista y otros especialistas médicos ">3.1 Internista y otros especialistas médicos </option>
                                    <option value="3.2 Cirujano y otros especialistas quirúrgicos ">3.2 Cirujano y otros especialistas quirúrgicos </option>
                                    <option value="3.3 Anestesista/ reanimador / médico de urgencias">Anestesista/ reanimador / médico de urgencias</option>
                                    <option value="3.4 Pediatría">3.4 Pediatría</option>
                                    <option value="3.5 Ginecólogo">3.5 Ginecólogo</option>
                                    <option value="3.6 Medico de Consulta Externa">3.6 Medico de Consulta Externa</option>
                                    <option value="3.7 Estudiante de medicina">3.7 Estudiante de medicina</option>
                                </optgroup>
                                <optgroup label='otra categoría profesional'>
                                    <option value="4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)">4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)</option>
                                    <option value="4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)">4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)</option>
                                    <option value="4.3 Otros (nutricionista, odontólogo, trabajador">4.3 Otros (nutricionista, odontólogo, trabajador</option>
                                    <option value="4.4 Estudiante (no recogido en los anteriores)">4.4 Estudiante (no recogido en los anteriores)</option>
                                </optgroup>
                        </select>
                        <Input
                            label='Número'
                        /> 
                        <h4>Oportunidad 4</h4>
                        <h4>Indicación</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <CheckBox
                            label='Antes de tener contacto con el
                            paciente'
                        />
                        <CheckBox
                            label='Antes de realizar una tarea
                            limpia o escéptica'
                        />
                        <CheckBox
                            label=' Después de tener contacto
                            con fluidos corporales'
                        />
                        <CheckBox
                            label='después de tener contacto
                            con el paciente'
                        />
                        <CheckBox
                            label='Después de tener contacto
                            con el entorno del paciente'
                        />
                        <h4>HM Acción</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <option value="FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol">FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol</option>
                                <option value="LM: acción de HM que consiste en lavarse">las manos con agua y jabón</option>
                                <option value="Perdida: no se realiza ninguna acción de HM">Perdida: no se realiza ninguna acción de HM</option>
                        </select>
                        <h4>Categoría del profesional</h4>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <optgroup label='Enfermera'>
                                    <option value="1.1 Enfermera">1.1 Enfermera</option>
                                    <option value="1.2 Estudiante ">1.2 Estudiante </option>
                                </optgroup>
                                <optgroup label='Auxiliar'>
                                    <option value="2.1 Auxiliar de enfermería">2.1 Auxiliar de enfermería</option>
                                </optgroup>
                                <optgroup label='Médico'>
                                    <option value="3.1 Internista y otros especialistas médicos ">3.1 Internista y otros especialistas médicos </option>
                                    <option value="3.2 Cirujano y otros especialistas quirúrgicos ">3.2 Cirujano y otros especialistas quirúrgicos </option>
                                    <option value="3.3 Anestesista/ reanimador / médico de urgencias">Anestesista/ reanimador / médico de urgencias</option>
                                    <option value="3.4 Pediatría">3.4 Pediatría</option>
                                    <option value="3.5 Ginecólogo">3.5 Ginecólogo</option>
                                    <option value="3.6 Medico de Consulta Externa">3.6 Medico de Consulta Externa</option>
                                    <option value="3.7 Estudiante de medicina">3.7 Estudiante de medicina</option>
                                </optgroup>
                                <optgroup label='otra categoría profesional'>
                                    <option value="4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)">4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)</option>
                                    <option value="4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)">4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)</option>
                                    <option value="4.3 Otros (nutricionista, odontólogo, trabajador">4.3 Otros (nutricionista, odontólogo, trabajador</option>
                                    <option value="4.4 Estudiante (no recogido en los anteriores)">4.4 Estudiante (no recogido en los anteriores)</option>
                                </optgroup>
                        </select>
                        <Input
                            label='Número'
                        /> 
                        <h4>Oportunidad 5</h4>
                        <h4>Indicación</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <CheckBox
                            label='Antes de tener contacto con el
                            paciente'
                        />
                        <CheckBox
                            label='Antes de realizar una tarea
                            limpia o escéptica'
                        />
                        <CheckBox
                            label=' Después de tener contacto
                            con fluidos corporales'
                        />
                        <CheckBox
                            label='después de tener contacto
                            con el paciente'
                        />
                        <CheckBox
                            label='Después de tener contacto
                            con el entorno del paciente'
                        />
                        <h4>HM Acción</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <option value="FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol">FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol</option>
                                <option value="LM: acción de HM que consiste en lavarse">las manos con agua y jabón</option>
                                <option value="Perdida: no se realiza ninguna acción de HM">Perdida: no se realiza ninguna acción de HM</option>
                        </select>
                        <h4>Categoría del profesional</h4>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <optgroup label='Enfermera'>
                                    <option value="1.1 Enfermera">1.1 Enfermera</option>
                                    <option value="1.2 Estudiante ">1.2 Estudiante </option>
                                </optgroup>
                                <optgroup label='Auxiliar'>
                                    <option value="2.1 Auxiliar de enfermería">2.1 Auxiliar de enfermería</option>
                                </optgroup>
                                <optgroup label='Médico'>
                                    <option value="3.1 Internista y otros especialistas médicos ">3.1 Internista y otros especialistas médicos </option>
                                    <option value="3.2 Cirujano y otros especialistas quirúrgicos ">3.2 Cirujano y otros especialistas quirúrgicos </option>
                                    <option value="3.3 Anestesista/ reanimador / médico de urgencias">Anestesista/ reanimador / médico de urgencias</option>
                                    <option value="3.4 Pediatría">3.4 Pediatría</option>
                                    <option value="3.5 Ginecólogo">3.5 Ginecólogo</option>
                                    <option value="3.6 Medico de Consulta Externa">3.6 Medico de Consulta Externa</option>
                                    <option value="3.7 Estudiante de medicina">3.7 Estudiante de medicina</option>
                                </optgroup>
                                <optgroup label='otra categoría profesional'>
                                    <option value="4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)">4.1 Terapeuta (fisioterapeuta, terapeuta ocupacional,fonoaudiólogo, etc.)</option>
                                    <option value="4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)">4.2 Técnico (radiólogo, técnico de cardiología, técnico de laboratorio...)</option>
                                    <option value="4.3 Otros (nutricionista, odontólogo, trabajador">4.3 Otros (nutricionista, odontólogo, trabajador</option>
                                    <option value="4.4 Estudiante (no recogido en los anteriores)">4.4 Estudiante (no recogido en los anteriores)</option>
                                </optgroup>
                        </select>
                        <Input
                            label='Número'
                        /> 
                        <h4>Oportunidad 6</h4>
                        <h4>Indicación</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <CheckBox
                            label='Antes de tener contacto con el
                            paciente'
                        />
                        <CheckBox
                            label='Antes de realizar una tarea
                            limpia o escéptica'
                        />
                        <CheckBox
                            label=' Después de tener contacto
                            con fluidos corporales'
                        />
                        <CheckBox
                            label='después de tener contacto
                            con el paciente'
                        />
                        <CheckBox
                            label='Después de tener contacto
                            con el entorno del paciente'
                        />
                        <h4>HM Acción</h4>
                        <small> Razón(es) que motiva(n) la acción de higiene de manos; deben registrarse todas las
                        indicaciones que pueden aplicarse en un momento dado.</small>
                        <select className='antibioticos--container__select' name='Servicio'>
                            <option hidden defaultValue>Selecciona una opción</option>
                                <option value="FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol">FA: acción de HM que consiste en frotárselas con una sustancia a base de alcohol</option>
                                <option value="LM: acción de HM que consiste en lavarse">las manos con agua y jabón</option>
                                <option value="Perdida: no se realiza ninguna acción de HM">Perdida: no se realiza ninguna acción de HM</option>
                        </select>
                        <div className="lavado--container__btn">
                            <Button
                                title='Enviar'
                            /> 
                        </div>
                    </form>
                </div>
            </div>
        <Navbar/>
        </>
      
    )
}

export default LavadoManos
