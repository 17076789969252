import React, { useState }  from 'react';
import './Curacion.scss';
import { useHistory } from 'react-router-dom';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';

function Curacion() {

       //get user and date
       const currentUser = app.auth().currentUser.email;
       const CurrentDate = new Date();
   
       //use History hook
       const history = useHistory();
   
       //set state for a data
       const [date, setDate] = useState('');
       const [curacion1, setCuracion1] = useState('');
       const [curacion2, setCuracion2] = useState('');
       const [curacion3, setCuracion3] = useState('');
       const [curacion4, setCuracion4] = useState('');
       const [curacion5, setCuracion5] = useState('');
       const [curacion6, setCuracion6] = useState('');
       const [curacion7, setCuracion7] = useState('');
       const [curacion8, setCuracion8] = useState('');
       const [curacion9, setCuracion9] = useState('');
       const [curacion10, setCuracion10] = useState('');

       //data
       const data = [
           {
                key: '1',
                label: '1. Indicación pertinente para curación',
                value: curacion1,
                onChange: (e) => setCuracion1(e.target.value)
           },
           {
                key: '2',
                label: '2. Realiza lavado de manos estricto',
                value: curacion2,
                onChange: (e) => setCuracion2(e.target.value)
           },
           {
                key: '3',
                label: '3. Usa elementos de barrera completos y correspondiente (gafas, respirador,bata y guantes).',
                value: curacion3,
                onChange: (e) => setCuracion3(e.target.value)
           },
           {
                key: '4',
                label: '4. Realiza asepsia con clorhexidina más alcohol',
                value: curacion4,
                onChange: (e) => setCuracion4(e.target.value)
           },
           {
                key: '5',
                label: '5. Cubre el catéter con apósito transparente',
                value: curacion5,
                onChange: (e) => setCuracion5(e.target.value)
           },
           {
                key: '6',
                label: '6. Rotular apósito transparente con fecha de inserción, curación y nombre de quien realiza el procedimiento',
                value: curacion6,
                onChange: (e) => setCuracion6(e.target.value)
           },
       ]

       //send data to firestore
       const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('Curación').add({
            currentUser: currentUser,
            CurrentDate: CurrentDate,
            date: date,
            curacion1: curacion1,
            curacion2: curacion2,
            curacion3: curacion3,
            curacion4: curacion4,
            curacion5: curacion5,
            curacion6: curacion6,
            curacion7: curacion7,
            curacion8: curacion8,
            curacion9: curacion9,
            curacion10: curacion10,
        })
        .then(() => {
            swal({
                title: '¡Exito!',
                text: 'Los datos han sido enviados exitosamente',
                icon: 'success',
                button: 'Seguir'
            });
        })
        .then(() => {
            history.goBack();
        })
        .catch((error) => {
            console.log(error)
        })
       }
       
    return (
        <div className='curacion'>
            <Topbar/>
            <h2>Curación</h2>
            <div className="curacion--container">
                <form action="" onSubmit={handleSubmit}>
                    <Input
                        label='Fecha'
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    /> 
                {
                    data.map(data => 
                        <SelectInput
                            key={data.key}
                            label={data.label}
                            value={data.value}
                            onChange={data.onChange}
                        />
                    )
                }
                <h2>Retiro del Cateter</h2>
                    <Input
                        label='Fecha de retiro'
                        type='date'
                        value={curacion7}
                        onChange={(e) => setCuracion7(e.target.value)}
                    /> 
                       <h4>Causa del retiro</h4>
                    <select className='antibioticos--container__select' value={curacion8}  onChange={(e) => setCuracion8(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Suspención de tratamiento">Suspención de tratamiento</option>
                        <option value="Infiltración">Infiltración</option>
                        <option value="Flebitis">Flebitis</option>
                        <option value="Signos de infección">Signos de infección</option>
                        <option value="Otro">Otro</option>
                    </select>
                    <Input 
                        label='Si esxgosite otro, especifica'
                        type='text'
                        value={curacion9}
                        onChange={(e) => setCuracion9(e.target.value)}
                    />
                       <h4>Infección</h4>
                    <select className='antibioticos--container__select' value={curacion10}  onChange={(e) => setCuracion10(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                    </select>
                
                <div className="curacion--container__btn">
                    <Button
                        title='Enviar'
                    />
                </div>
                </form>
            </div>

            <Navbar/>  
        </div>
    )
}

export default Curacion
