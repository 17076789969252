import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Hospitalizacion.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import MonthsInput from '../../../components/MonthsInput/MonthsInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';

function Hospitalizacion() {
    //get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //useHistory Hook
    const history = useHistory();

    //data to get captured
    const [date, setDate] = useState('');
    const [indice1, setIndice1] = useState('');
    const [indice2, setIndice2] = useState('');
    const [infeccion1, setInfeccion1] = useState('');
    const [infeccion2, setInfeccion2] = useState('');
    const [istu1, setIstu1] = useState('');
    const [istu2, setIstu2] = useState('');
    const [istu3, setIstu3] = useState('');
    const [istu4, setIstu4] = useState('');
    const [istu5, setIstu5] = useState('');
    const [istu6, setIstu6] = useState('');
    const [istu7, setIstu7] = useState('');
    const [istu8, setIstu8] = useState('');


    //data 
    const data = [
        {
            key: '1',
            label:'1. Indice Grupo Hospitalización',
            placeholder: 'Numero de casos IAAS',
            value: indice1,
            onChange: (e) => setIndice1(e.target.value),
        },
        {
            key: '2',
            label:'',
            placeholder: 'Numero de egresos periodo',
            value: indice2,
            onChange: (e) => setIndice2(e.target.value),
        },
        {
            key: '3',
            label:'2. Tasa de Infecciones en Hospitalización',
            placeholder: 'Numero de casos IAAS',
            value: infeccion1,
            onChange: (e) => setInfeccion1(e.target.value),
        },
        {
            key: '4',
            label:'',
            placeholder: 'Numero de dias paciente',
            value: infeccion2,
            onChange: (e) => setInfeccion2(e.target.value),
        },
        {
            key: '5',
            label:'3. Tasa de infección de vías urinarias asociadas a sonda vesical en hospitalización (ISTU-AC)	',
            placeholder: 'Número de ISTU-AC',
            value: istu1,
            onChange: (e) => setIstu1(e.target.value),
        },
        {
            key: '6',
            label:'',
            placeholder: 'Total días catéter urinario',
            value: istu2,
            onChange: (e) => setIstu2(e.target.value),
        },
        {
            key: '7',
            label:'4. Proporción infección del tracto urinario (ISTU) en hospitalización',
            placeholder: 'Número de ISTU',
            value: istu3,
            onChange: (e) => setIstu3(e.target.value),
        },
        {
            key: '8',
            label:'',
            placeholder: 'Número de egresos',
            value: istu4,
            onChange: (e) => setIstu4(e.target.value),
        },
        {
            key: '9',
            label:'5. Tasa de infección del torrente sanguineo asociado a cateter central (ITS-AC)',
            placeholder: 'Número de ITS-AC',
            value: istu5,
            onChange: (e) => setIstu5(e.target.value),
        },
        {
            key: '10',
            label:'',
            placeholder: 'Total dias de catéter central',
            value: istu6,
            onChange: (e) => setIstu6(e.target.value),
        },
        {
            key: '11',
            label:'6. Tasa de flebitis bacteriana',
            placeholder: 'Número de casos de flebitis',
            value: istu7,
            onChange: (e) => setIstu7(e.target.value),
        },
        {
            key: '12',
            label:'',
            placeholder: 'Total dias de catéter periférico',
            value: istu8,
            onChange: (e) => setIstu8(e.target.value),
        },
    ]
    //send data to firestore    
    const handleSubmit = (e) => {
        e.preventDefault();
        if(date.length === 0 || indice2.length === 0 || infeccion1.length === 0 || infeccion2.length === 0 ||
             istu1.length === 0 || istu2.length === 0 || istu3.length === 0 || istu4.length === 0 || istu5.length === 0
             || istu6.length === 0 || istu7.length === 0 || istu8.length === 0){
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            });
        }
       else {
            db.collection('Hospitalizacion').add({
                user: currentUser,
                currentDate: currentDate,
                date: date,
                indice1: indice1,
                indice2: indice2,
                infeccion1: infeccion1,
                infeccion2: infeccion2,
                istu1: istu1,
                istu2: istu2,
                istu3: istu3,
                istu4: istu4,
                istu5: istu5,
                istu6: istu6,
                istu7: istu7,
                istu8: istu8,
            })
            .then(() => {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(() => {
                setDate('');
                setIndice1('');
                setIndice2('');
                setInfeccion1('');
                setInfeccion2('');
                setIstu1('');
                setIstu2('');
                setIstu3('');
                setIstu4('');
                setIstu5('');
                setIstu6('');
                setIstu7('');
                setIstu8('');
            })
            .then(function () {
                history.goBack();
           })
            .catch((error) => {
                console.log(error)  
            })
        }  
    }

    return (
        <div className='hospitalizacion'>   
            <Topbar/>
            <div className="hospitalizacion--container">
                <h2>Hospitalización</h2>
                <MonthsInput
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                <div className="hospitalizacion--container__form">
                    <form action="" onSubmit={handleSubmit}>
                 
                        {
                            data.map(data => 
                                    <Input 
                                        key={data.key}
                                        label={data.label}
                                        placeholder={data.placeholder}
                                        value={data.value}
                                        onChange={data.onChange}

                                    />
                                )
                        }
                        <div className="cirugia--container__btn">
                            <Button
                                title='Enviar'
                            />
                        </div>

                    </form>
                </div>
            </div>
            <Navbar/>
        </div>
    )
}

export default Hospitalizacion
