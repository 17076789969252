import React from 'react';
import './Listas.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/Topbar/Topbar';
import MenuCard from '../../components/MenuCard/MenuCard';
import Navbar from '../../components/Navbar/Navbar';

function Listas({ name }) {

    const data = [
        {
            key: '1',
            path: '/aislamiento',
            name: 'Lista de chequeo: Aislamiento'
        },
        {
            key: '2',
            path: '/protocolo-aislamiento',
            name: 'Adherencia Protocolo de Aislamiento'
        },
    
    ]

    const data2 = [

         {
            key: '3',
            path: '/istu-ac',
            name: 'Lista de chequeo: Inserción'
        },
        {
            key: '4',
            path: '/istu-seguimiento',
            name: 'Lista de chequeo: Seguimiento diario a sonda vesical'
        },
    ]

    const data3 = [
   
        {
            key: '5',
            path: '/manejo-cateter',
            name: 'Manejo de Catéter Central'
        },
        {
            key: '6',
            path: '/insercion-cateter',
            name: 'Inserción del Catéter Central'
        },
    ]

    const data4 = [
        {
            key: '7',
            path: '/nav',
            name: 'Segumiento diario'
        },
   
    ]
    const data5 = [

        {
            key: '8',
            path: '/periferico',
            name: 'Inserción y seguimiento cateterismo periférico'
        },
        {
            key: '9',
            path: '/seguimiento',
            name: 'Seguimiento de las condiciones de acceso venoso'
        },
    ]
    return (
        <div className='listas'>
            <Topbar/>  
            <div className="listas--container">
                <h2>Listas de Chequeo</h2>
            <div className="listas--container__card">
                <h4>Higiene de manos</h4>
                <Link to='/lavado-manos' key={data.key} style={{textDecoration: 'none', color: '#333'}}>
                    <MenuCard
                        name='Higiene de manos'
                    />
                </Link>
                <h4>Aislamiento</h4>
                {
                    
                    data.map( data => 
                        <Link to={data.path} key={data.key} style={{textDecoration: 'none', color: '#333'}}>
                          
                            <MenuCard 
                                key={data.key}
                                name={data.name}
                            />
                        </Link>
                        )
                }
                <h4>Prevención de neumonía asociada a la ventilación</h4>
                {
                    
                    data4.map( data4 => 
                        <Link to={data4.path} key={data4.key} style={{textDecoration: 'none', color: '#333'}}>
                          
                            <MenuCard 
                                key={data4.key}
                                name={data4.name}
                            />
                        </Link>
                        )
                }
                <h4>Prevención de infección sintomática del tracto urinario asociada a sonda vesical</h4>
                {
                    
                    data2.map( data2 => 
                        <Link to={data2.path} key={data2.key} style={{textDecoration: 'none', color: '#333'}}>
                          
                            <MenuCard 
                                key={data2.key}
                                name={data2.name}
                            />
                        </Link>
                        )
                }
                <h4>Prevencion de flebitis bacteriana </h4>
                {
                    
                    data5.map( data5 => 
                        <Link to={data5.path} key={data5.key} style={{textDecoration: 'none', color: '#333'}}>
                          
                            <MenuCard 
                                key={data5.key}
                                name={data5.name}
                            />
                        </Link>
                        )
                }
                <h4>Prevención de infección de torrente sanguineo asociada a cateter</h4>
                {
                    
                    data3.map( data3 => 
                        <Link to={data3.path} key={data3.key} style={{textDecoration: 'none', color: '#333'}}>
                          
                            <MenuCard 
                                key={data3.key}
                                name={data3.name}
                            />
                        </Link>
                        )
                }
              
            </div>
            </div>
            <Navbar/>
        </div>
    )
}

export default Listas
