import React, { useState, useContext } from 'react';
import './Login.scss';
import { Redirect } from 'react-router-dom';
import { app } from '../../firebase/firebaseConfig';
import { UserContext } from '../../providers/UserProvider';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import mainLogo from '../../assets/brand/eye.png';

function Login() {

//get context of current user
const currentUser = useContext(UserContext)
// set state fir data
const [user, setUser] = useState('');
const [password, setPassword] = useState('');
const [errorMsg, setErrorMsg] = useState('');

const activeUser = app.auth().currentUser;

//authentication
const handleLogin = (e) => {
    e.preventDefault()
    app.auth().signInWithEmailAndPassword(user, password)
    .catch((error)=> {
      if(activeUser == null) {
          setErrorMsg('El usuario o la contraseña son incorrectos')
      }
    })
}

//redirect if current user is true.
 if(currentUser){
     return <Redirect to='/dashboard' />
   }
    return (
        <div className='login-container'>
            <div className="login-container__logo">
                <img src={mainLogo} alt=""/>
            </div>
            <div className="login--container__text">
                <h2>Bienvenido a Vieci.</h2>
            </div>
            <div className="login--container__form">
                <form onSubmit={handleLogin}>
                    <Input
                        label='Usuario'  
                        type='email'
                        placeholder='hospital@vieci.com.co'
                        className=''
                        onChange={(e)=>setUser(e.target.value)}
                    />
                    <Input
                        label='Contraseña'  
                        type='password'
                        placeholder=''
                        className=''
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                    <Button
                        title='Ingresar'
                        className='login-container__button'
                    />
                       {
                        errorMsg ?
                        (
                            <>
                            <br></br>
                            <div style={{ color: 'red' }}>{errorMsg}</div>
                            </>
                        )
                        :
                        (
                            <span></span>
                        )
                    }
                    <br/>
                    <small>¿Olvidaste tu contraseña? <a href="mailto:sergio.leal@vieci.com.co?subject=OLVIDE%20MI%20CONTRASEÑA&body=He%20olvidado%20mi%20contraseña%20y%20quisiera%20reestablecerla">haz click aquí</a>  para reestablecerla</small>
                </form>
            </div>   
        </div>
    )
}

export default Login