import React, { useState } from 'react';
import './IstuSeguimiento.scss';
import { useHistory } from 'react-router-dom';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';

function IstuSeguimiento () {
    // Get user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    // use history hook
    const history = useHistory();

    //set state for data
    const [name, setName] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [age, setAge] = useState('');
    const [insertionDate, setInsertionDate] = useState('');
    const [bed, setBed] = useState('');
    const [diagnostic, setDiagnostic] = useState('');
    const [probe, setProbe] = useState('');
    const [caliber, setCaliber] = useState('');
    const [issues, setIssues] = useState('');
    const [profesional, setProfesional] = useState('');
    const [other, setOther] = useState('');
    const [profesionalName, setProfesionalName] = useState(''); 

    //form info
    const [istu1, setIstu1] = useState('');
    const [istu2, setIstu2] = useState('');
    const [istu3, setIstu3] = useState('');
    const [istu4, setIstu4] = useState('');
    const [istu5, setIstu5] = useState('');
    const [istu6, setIstu6] = useState('');

    const data = [
        {
            key: '1',
            label: 'Nombres Completos del Paciente',
            type: 'text',
            value: name,
            onChange: (e) => setName(e.target.value)
        },
        {
            key: '1.5',
            label: 'Tipo de Documento',
            type: 'text',
            value: idType,
            onChange: (e) => setIdType(e.target.value)
        },
        {
            key: '2',
            label: 'Identificación',
            type: 'number',
            value: id,
            onChange: (e) => setId(e.target.value)
        },
        {
            key: '3',
            label: 'Edad',
            type: 'number',
            value: age,
            onChange: (e) => setAge(e.target.value)
        },
        {
            key: '4',
            label: 'Fecha de inserción',
            type: 'date',
            value: insertionDate,
            onChange: (e) => setInsertionDate(e.target.value)
        },
        {
            key: '5',
            label: 'Cama',
            type: 'text',
            value: bed,
            onChange: (e) => setBed(e.target.value)
        },
        {
            key: '6',
            label: 'Diagnóstico',
            type: 'text',
            value: diagnostic,
            onChange: (e) => setDiagnostic(e.target.value)
        },
        {
            key: '7',
            label: 'Tipo de sonda',
            type: 'text',
            value: probe,
            onChange: (e) => setProbe(e.target.value)
        },
        {
            key: '8',
            label: 'Calibre',
            type: 'text',
            value: caliber,
            onChange: (e) => setCaliber(e.target.value)
        },
    ]

    const options = [
        {
            key: '1',
            label: '1. Cuenta con lista de verificación y valoración inicial seguimiento de sonda vesical',
            value: istu1,
            onChange: (e) => setIstu1(e.target.value)
        },
        {
            key: '2',
            label: '2. Utiliza sistema cerrado',
            value: istu2,
            onChange: (e) => setIstu2(e.target.value)
        },
        {
            key: '3',
            label: '3. El sistema ubicado por debajo del nivel de la vegiga',
            value: istu3,
            onChange: (e) => setIstu3(e.target.value)
        },
        {
            key: '4',
            label: '4. La sonda está fijada a la piel del paciente teniendo en cuenta que en los hombres es en la cresta iliaca y en mujeres en cara interna del muslo',
            value: istu4,
            onChange: (e) => setIstu4(e.target.value)
        },
        {
            key: '5',
            label: '5. El cistoflo está rotulado (nombre, servicio y fecha inserción)',
            value: istu5,
            onChange: (e) => setIstu5(e.target.value)
        },
        {
            key: '6',
            label: '6. Utiliza los EPP requeridos',
            value: istu6,
            onChange: (e) => setIstu6(e.target.value)
        },
    ]

    //send data to firestore
    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.length === 0 || idType.length === 0 || id.length === 0 
            || age.length === 0 || insertionDate.length === 0 || bed.length === 0 
            || diagnostic.length === 0 || probe.length === 0 || caliber.length === 0 ||
            issues.length === 0 || profesional.length === 0 || other.length === 0 || profesionalName.length === 0 ) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('ISTU-AC: Seguimiento').add({
                currentUser: currentUser,
                currentDate: currentDate,
                name: name,
                idType: idType,
                id: id,
                age: age,
                insertionDate: insertionDate,
                bed: bed,
                diagnostic: diagnostic,
                probe: probe,
                caliber: caliber,
                issues: issues,
                profesional: profesional,
                other: other,
                profesionalName: profesionalName,
                istu1: istu1,
                istu2: istu2,
                istu3: istu3,
                istu4: istu4,
                istu5: istu5,
                istu6: istu6,
            })
            .then(() => {  
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(() => {
                history.goBack();
            })
            .catch((error) => {
                console.log(error)
            })
        } 
    }


    return (
        <div className='istu'>
            <Topbar/>
            <h2>Lista de chequeo: Seguimiento diario a sonda vesical</h2>
            <div className="istu--container">
                <form action="" onSubmit={handleSubmit}> 
                        {
                            data.map(data => 
                                    <Input 
                                        key={data.key}
                                        label={data.label}
                                        type={data.type}
                                        value={data.value}
                                        onChange={data.onChange}
                                    />
                                )
                        }
                        
                    <h4>¿Durante el procedimiento se presentaron complicaciones?</h4>
                    <select className='antibioticos--container__select' value={issues} onChange={(e) => setIssues(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                    </select>
                    <h4>Profesional que realizo el procedimiento</h4>
                    <select className='antibioticos--container__select' value={profesional} onChange={(e) => setProfesional(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Médico">Médico</option>
                        <option value="Enfermero">Enfermero</option>
                        <option value="Otro">Otro</option>
                    </select>
                    <Input 
                        label='Si escogiste otro, especifica'
                        type='text'
                        value={other}
                        onChange={(e) => setOther(e.target.value)}
                    />
                    <Input 
                        label='Nombre'
                        type='text'
                        value={profesionalName}
                        onChange={(e) => setProfesionalName(e.target.value)}
                    />
                    <h2>Lista de chequeo Inserción</h2>
                    {
                        options.map(options => 
                                <SelectInput
                                    key={options.key}
                                    label={options.label}
                                    value={options.value}
                                    onChange={options.onChange} 
                                />
                            )
                    }
                    <div className="istu--container__btn">
                        <Button
                            title=' Enviar'
                        />
                    </div>
                </form>
            </div>
            <Navbar/>  
        </div>
    )
}

export default IstuSeguimiento
