import React, { useState } from 'react';
import './Casos.scss';
import { useHistory } from 'react-router-dom';
import { app, db } from '../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../components/Topbar/Topbar';
import Input from '../../components/Input/Input';
import TextArea from '../../components/TextArea/TextArea';
import SelectInput from '../../components/SelectInput/SelectInput';
import Button from '../../components/Button/Button';
import Navbar from '../../components/Navbar/Navbar';


function Casos() {
    //Get current user and current date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();

    //get history
    const history = useHistory();

    //state declaration for data
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [idType, setIdType] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [ageYears, setAgeYears] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [eps, setEps] = useState('');
    const [location, setLocation] = useState('');
    const [bed, setBed] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [diagnosis, setDiagnosis] = useState('');
    const [diagnosisDate, setDiagnosisDate] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [profesional, setProfesional] = useState('');
    const [noIaas, setNoIaas] = useState('');
    const [pathology, setPathology] = useState('');
    const [covid, setCovid] = useState('');
    const [atention, setAtention] = useState('');
    const [profesionalName, setProfesionalName] = useState('');
    const [suspect, setSuspect] = useState('');
    const [neoNato, setNeoNato]= useState('');
    const [weight, setWeight] = useState('');
    const [iaasType, setIaasType] = useState('');
    const [iaasFactor, setIaasFactor] = useState('');
    const [iaasCulture, setIaasCulture] = useState('');
    const [details, setDetails] = useState('');
    const [sampleDate, setSampleDate] = useState('');
    const [sampleType, setSampleType] = useState('');
    const [sampleDetails, setSampleDetails] = useState('');
    const [antibiotic, setAntibiotic] = useState('');
    const [dosis, setDosis] = useState('');
    const [via, setVia] = useState('');
    const [frecuency, setFrecuency] = useState('');
    const [initialTreatment, setInitialTreatment] = useState('');
    const [endTreatment, setEndTreatment] = useState('');
    const [antibioticTreatment, setAntibioticTreatment] = useState('');
    const [event, setEvent] = useState('');
    const [profilaxis, setProfilaxis] = useState('');
    const [antibiotic2, setAntibiotic2] = useState('');
    const [antibioticName, setAntibioticName] = useState('');
    const [antibioticDosis, setAntibioticDosis] = useState('');
    const [secondDosis, setSecondDosis] = useState('');
    const [antibiotic3, setAntibiotic3] = useState('');
    const [antibioticType, setAntibioticType] = useState('');
    const [patientsDestiny, setPatientsDestiny] = useState('');
    const [admisionType, setAdmisionType] = useState('');
    const [procedure, setProcedure] = useState('');
    const [surgeonName, setSurgeonName] = useState('');
    const [surgeonName2, setSurgeonName2] = useState('');
    const [procedureDate, setProcedureDate] = useState('');
    const [room, setRoom] = useState('');
    const [duration, setDuration] = useState('');
    const [blood, setBlood] = useState('');
    const [transfusion, setTransfusion] = useState('');
    const [weirdMaterial, setWeirdMaterial] = useState('');
    const [shave, setShave] = useState('');
    const [woundType, setWoundType] = useState('');
    const [procedureType, setProcedureType] = useState('');
    const [risk, setRisk] = useState('');
    const [clasification, setClasification] = useState('');
    const [asa, setAsa] = useState('');
    const [surgery2, setSurgery2] = useState('');
    const [wound2, setWound2] = useState('');
    const [score, setScore] = useState('');
    const [device, setDevice] = useState('');
    const [cateter, setCateter] = useState('');
    const [cateterPlace, setCateterPlace] = useState('');
    const [insertionDate, setInsertionDate] = useState('');
    const [retireDate, setRetireDate] = useState('');
    const [total, setTotal] = useState('');
    const [egreso, setEgreso] = useState('');
    const [death, setDeath] = useState('');
    const [preventable, setPreventable] = useState('');
    const [estancia, setEstancia] = useState('');
    const [estancia2, setEstancia2] = useState('');
    const [searchType,setSearchType] = useState('');
    const [nurseName, setNurseName] = useState('');
    const [infectologistName, setInfectologistaName] = useState('');
    const [iaas, setIaas] = useState('');

    //data 

    const data = [
        {   
            key: '1',
            label: 'Fecha',
            type:'date',
            value: date,
            onChange: (e)=> setDate(e.target.value)
        },
        {   
            key: '2',
            label: 'Nombre del paciente',
            type:'text',
            value: name,
            onChange: (e)=> setName(e.target.value)
        },
        {   
            key: '2.5',
            label: 'Tipo de documento',
            type:'text',
            value: idType,
            onChange: (e)=> setIdType(e.target.value)
        },
        {   
            key: '3',
            label: 'Número de identificación',
            type:'number',
            value: id,
            onChange: (e)=> setId(e.target.value)
        },
        {   
            key: '4',
            label: 'Fecha de nacimiento',
            type:'date',
            value: birthDate,
            onChange: (e)=> setBirthDate(e.target.value)
        },
        {   
            key: '5',
            label: 'Edad',
            type:'number',
            value: ageYears,
            onChange: (e)=> setAgeYears(e.target.value)
        },
        {   
            key: '6',
            label: 'Número de contacto',
            type:'number',
            value: phone,
            onChange: (e)=> setPhone(e.target.value)
        },
    ]

    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('Casos de IAAS').add({
            user: currentUser,
            currentDate: currentDate,
            date: date,
            name: name,
            idType: idType,
            id: id,
            birthDate: birthDate,
            ageYears: ageYears,
            phone: phone,
            gender: gender,
            eps: eps,
            location: location,
            bed: bed,
            entryDate: entryDate,
            departureDate: departureDate,
            diagnosis: diagnosis,
            diagnosisDate: diagnosisDate,
            specialty: specialty,
            profesional: profesional,
            noIaas: noIaas,
            pathology: pathology,
            covid: covid,
            atention: atention,
            profesionalName: profesionalName,
            suspect: suspect,
            neoNato:neoNato,
            weight: weight,
            iaasType: iaasType,
            iaasFactor: iaasFactor,
            iaasCulture: iaasCulture,
            details: details,
            sampleDate: sampleDate,
            sampleType: sampleDetails, 
            sampleDetails: sampleDetails,
            antibiotic: antibiotic,
            dosis: dosis,
            via: via,
            frecuency: frecuency,
            initialTreatment: initialTreatment,
            endTreatment: endTreatment,
            antibioticTreatment: antibioticTreatment, 
            event: event,
            profilaxis: profilaxis,
            antibiotic2: antibiotic2,
            antibioticName: antibioticName,
            antibioticDosis: antibioticDosis,
            secondDosis: secondDosis,
            antibiotic3: antibiotic3,
            antibioticType: antibioticType,
            patientsDestiny: patientsDestiny,
            admisionType: admisionType,
            procedure: procedure,
            surgeonName: surgeonName,
            surgeonName2: surgeonName2,
            procedureDate: procedureDate,
            room: room,
            duration: duration,
            blood: blood,
            transfusion: transfusion,
            weirdMaterial: weirdMaterial,
            shave: shave,
            woundType: woundType,
            procedureType: procedureType,
            risk: risk,
            clasification: clasification,
            asa: asa,
            surgery2: surgery2,
            wound2: wound2,
            score: score,
            device: device,
            cateter: cateter,
            cateterPlace: cateterPlace,
            insertionDate: insertionDate,
            retireDate: retireDate,
            total: total,
            egreso: egreso,
            death: death,
            preventable: preventable,
            estancia: estancia,
            estancia2: estancia2,
            SearchType: searchType,
            nurseName: nurseName,
            infectologistName: infectologistName,
            iass: iaas
        })
        .then(()=> {
            swal({
                title: '¡Exito!',
                text: 'Los datos han sido enviados exitosamente',
                icon: 'success',
                button: 'Seguir'
            });
        })
        .then(()=> {
            history.goBack();
        })
        .catch((err)=> {
            alert('ERROR: Se ha producido un error al enviar los datos, por favor intenta de nuevo o contacta soporte')
        })
    }
    return (
        <div className='casos'>
            <Topbar/>
            <div className="casos--container">
                <h2>Casos de IAAS</h2>
                <div className="casos--container__form">
                    <form action="" onSubmit={handleSubmit}>
                        {
                            data.map(data => 
                                    <Input 
                                        key={data.key}
                                        label={data.label}
                                        type={data.type}
                                        value={data.value}
                                        onChange={data.onChange}
                                    />
                                )
                        }
                    <h4>Género</h4>
                    <select className='antibioticos--container__select' value={gender} onChange={(e)=>setGender(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Masculino">Masculino</option>
                        <option value="Femenino">Femenino</option>
                    </select>
                    <Input 
                        label='EPS'
                        type='text'
                        value={eps} 
                        onChange={(e)=>setEps(e.target.value)}
                    />
                    <h4>Ubicación</h4>
                    <select className='antibioticos--container__select' value={location} onChange={(e)=>setLocation(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Consulta prioritaria">Consulta prioritaria</option>
                        <option value="Radiología">Radiología</option>
                        <option value="Hospitalización">Hospitalización</option>
                        <option value="UCI intermedio adultos">UCI intermedio adultos</option>
                        <option value="Preparto">Preparto</option>
                        <option value="Recuperación">Recuperación</option>
                        <option value="Recuperación">UCI neonatal</option>
                    </select>

                    <h4>Cama/Consultorio/Cubículo</h4>
                    <select name="select_1" value={bed} onChange={(e)=>setBed(e.target.value)}>
                        <optgroup label="Consulta Prioritaria">
                            <option hidden defaultValue value="0">Selecciona una opción</option>
                            <option value="Consultorio 1">Consultorio 1</option>
                            <option value="Consultorio 2">Consultorio 2</option>
                        </optgroup>
                        <optgroup label="Radiología">
                            <option value="Rayos X">Rayos X</option>
                        </optgroup>
                        <optgroup label="Hospitalización">
                            <option value="101">101</option>
                            <option value="102A">102A</option>
                            <option value="102B">102B</option>
                            <option value="103A">103A</option>
                            <option value="103B">103B</option>
                            <option value="103C">103C</option>
                            <option value="103D">103D</option>
                            <option value="104A">104A</option>
                            <option value="104B">104B</option>
                            <option value="105A">105A</option>
                            <option value="105A">105B</option>
                            <option value="106A">106A</option>
                            <option value="106B">106B</option>
                            <option value="106C">106C</option>
                            <option value="106D">106D</option>
                            <option value="107A">107A</option>
                            <option value="107B">107B</option>
                            <option value="108A">108A</option>
                            <option value="108B">108B</option>
                        </optgroup>
                        <optgroup label="UCI Intermedios adulto">
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                        </optgroup>
                        <optgroup label="Preparto">
                            <option value="PRE01">PRE01</option>
                            <option value="PREO2">PREO2</option>
                            <option value="PRE03">PRE03</option>
                            <option value="PRE04">PRE04</option>
                            <option value="PRE05">PRE05</option>
                            <option value="PRE06">PRE06</option>
                            <option value="PRE07">PRE07</option>
                            <option value="PRE08">PRE08</option>
                            <option value="PRE09">PRE09</option>
                            <option value="PRE10">PRE10</option>
                            <option value="Sala de parto 1">Sala de parto 1</option>
                            <option value="Sala de parto 2">Sala de parto 2</option>
                            <option value="Quirofano 1">Quirofano 1</option>
                            <option value="Quirofano 2">Quirofano 2</option>
                        </optgroup>
                        <optgroup label="Recuperación">
                            <option value="REC01">REC01</option>
                            <option value="REC02">REC02</option>
                            <option value="REC03">REC03</option>
                            <option value="REC04">REC04</option>
                            <option value="REC05">REC05</option>
                            <option value="REC06">REC06</option>
                        </optgroup>
                        <optgroup label="Unidad neonatal">
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                            <option value="39">39</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="45">45</option>
                            <option value="46">46</option>
                            <option value="47">47</option>
                            <option value="48">48</option>
                            <option value="49">49</option>
                            <option value="50">50</option>
                        </optgroup>
                    </select>
                    <Input 
                        label='Fecha de ingreso'
                        type='date'
                        value={entryDate}
                        onChange={(e)=> setEntryDate(e.target.value)}
                    />
                    <Input 
                        label='Fecha de egreso'
                        type='date'
                        value={departureDate} 
                        onChange={(e)=>setDepartureDate(e.target.value)}
                    />
                    <TextArea
                        label='Diagnóstico'
                        name='ambiente'
                        cols='30'
                        rows='8'
                        value={diagnosis} 
                        onChange={(e)=>setDiagnosis(e.target.value)}
                    />
                    <Input 
                        label='Fecha del diagnóstico'
                        type='date'
                        value={diagnosisDate}
                         onChange={(e)=>setDiagnosisDate(e.target.value)}
                    />
                    <Input 
                        label='Especialidad tratante'
                        type='text'
                        value={specialty}
                         onChange={(e)=>setSpecialty(e.target.value)}
                    />
                    <Input 
                        label='Profesional'
                        type='text'
                        value={profesional}
                         onChange={(e)=>setProfesional(e.target.value)}
                    />
                    <Input 
                        label='No. IAAS'
                        type='text'
                        value={noIaas}
                         onChange={(e)=>setNoIaas(e.target.value)}
                    />
                    <Input 
                        label='¿Paciente presenta antecedentes médicos (Patologías Asociadas)?'
                        type='text'
                        value={pathology}
                         onChange={(e)=>setPathology(e.target.value)}
                    />
                    <h4>¿Paciente es Covid positivo?</h4>
                    <select className='antibioticos--container__select' value={covid} onChange={(e)=>setCovid(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                        <option value="No Aplica">No Aplica</option>
                    </select>
                    <Input 
                        label=' Especialidad de la atención'
                        type='text'
                        value={atention} 
                        onChange={(e)=>setAtention(e.target.value)}
                    />
                    <Input 
                        label=' Nombre profesional médico'
                        type='text'
                        value={profesionalName} 
                        onChange={(e)=>setProfesionalName(e.target.value)}
                    />
                        <h4>¿Sospecha de Infección asociada a la atención en salud?</h4>
                    <select className='antibioticos--container__select' value={suspect} onChange={(e)=>setSuspect(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                        <option value="No Aplica">No Aplica</option>
                    </select>
                    <SelectInput
                         label='¿El paciente es Neonato?'
                         value={neoNato} 
                         onChange={(e)=>setNeoNato(e.target.value)}
                    />
                    <Input 
                        label=' Sí el paciente es Neonato, ¿Cuál es el peso al nacer (en gramos)?'
                        type='text'
                        value={weight} 
                        onChange={(e)=>setWeight(e.target.value)}
                    />
                    <h4>Ingrese el tipo de IAAS diagnosticada</h4>
                    <select className='antibioticos--container__select' value={iaasType} onChange={(e)=>setIaasType(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Infección del torrente sanguineo confirmada por laboratorio">Infección del torrente sanguineo confirmada por laboratorio</option>
                        <option value="No">Infección sintomática del tratco urinario no asociada a catéter urinario</option>
                        <option value="infección del sitio quirúrgico de organo - espacio">infección del sitio quirúrgico de organo - espacio</option>
                        <option value="Neumonia definida clinicamente">Neumonia definida clinicamente</option>
                        <option value="Infección del sitio de incision quirúrgica profunda">Infección del sitio de incision </option>
                        <option value="Infección del torrente sanguineo confirmada por laboratorio">Infección del torrente sanguineo confirmada por laboratorio</option>
                    </select>
                    <h4>Factor Extrinseco Asociado a IAAS</h4>
                    <select className='antibioticos--container__select' value={iaasFactor} onChange={(e)=>setIaasFactor(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Catéter central">Catéter central</option>
                        <option value="Estancia hospitalaria">Estancia hospitalaria</option>
                        <option value="Orina">Orina</option>
                        <option value="Aspirado traqueal">Aspirado traqueal</option>
                    </select>
                    <SelectInput
                         label='¿La IAAS tiene cultivo microbiologico?'
                         value={iaasCulture}
                         onChange={(e)=>setIaasCulture(e.target.value)}
                    />
                    <TextArea
                        label='Detalle las observaciones del servicio'
                        name='observaciones'
                        cols='30'
                        rows='8'
                        value={details} 
                        onChange={(e)=>setDetails(e.target.value)}
                    />
                    <Input 
                        label=' Fecha Toma 1'
                        type='date'
                        value={sampleDate} 
                        onChange={(e)=>setSampleDate(e.target.value)}
                    />
                    <h4>Tipo de Cultivo Infección (Origen Muestra)</h4>
                    <select className='antibioticos--container__select' value={sampleType}  onChange={(e)=>setSampleType(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Sangre">Sangre</option>
                        <option value="Secreciones">Secreciones</option>
                        <option value="Procedimiento quirúrgico">Procedimiento quirúrgico</option>
                        <option value="Catéter periférico">Catéter periférico</option>
                    </select>
                    <TextArea
                        label='Ingrese los Microorganismos Asociados a la Infección (Germen) 1'
                        name='microorganismos'
                        cols='30'
                        rows='8'
                        value={sampleDetails} 
                        onChange={(e)=>setSampleDetails(e.target.value)}
                    />
                    <Input 
                        label=' Nombre Antibiótico'
                        type='text'
                        value={antibiotic} 
                        onChange={(e)=>setAntibiotic(e.target.value)}
                    />
                    <Input 
                        label=' Dosis (en gramos)'
                        type='text'
                        value={dosis} 
                        onChange={(e)=>setDosis(e.target.value)}
                    />
                    <h4>Via</h4>
                    <select className='antibioticos--container__select' value={via}  onChange={(e)=>setVia(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Oral">Oral</option>
                        <option value="IV">IV</option>
                    </select>
                    <Input 
                        label=' Frecuencia'
                        type='text'
                        value={frecuency}  
                        onChange={(e)=>setFrecuency(e.target.value)}
                    />
                    <Input 
                        label=' Fecha inicio tratamiento'
                        type='date'
                        value={initialTreatment}  
                        onChange={(e)=>setInitialTreatment(e.target.value)}
                    />
                    <Input 
                        label=' Fecha fin tratamiento'
                        type='date'
                        value={endTreatment} 
                        onChange={(e)=>setEndTreatment(e.target.value)}
                    />
                    <SelectInput
                        label='¿La IAAS Tiene Tratamiento Antibiótico?'
                        value={antibioticTreatment} 
                        onChange={(e)=>setAntibioticTreatment(e.target.value)}
                    />
                    <SelectInput
                        label='¿El evento se considera una infección de sitio operatorio?'
                        value={event} 
                        onChange={(e)=>setEvent(e.target.value)}
                    />
                    <SelectInput
                        label='¿Profilaxis antibiótica?'
                        value={profilaxis} 
                         onChange={(e)=>setProfilaxis(e.target.value)}
                    />
                    <SelectInput
                         label='¿Antibiótico?'
                         value={antibiotic2} 
                          onChange={(e)=>setAntibiotic2(e.target.value)}
                    />
                    <Input 
                        label=' Nombre del antibiótico'
                        type='text'
                        value={antibioticName}  
                        onChange={(e)=>setAntibioticName(e.target.value)}
                    />
                    <Input 
                        label=' Dosis'
                        type='text'
                        value={antibioticDosis}
                        onChange={(e)=>setAntibioticDosis(e.target.value)}
                    />
                    <SelectInput
                        label='¿Se aplica 2da dosis de antibiótico de acuerdo a protocolo?'
                        value={secondDosis}  
                        onChange={(e)=>setSecondDosis(e.target.value)}
                    />
                    <SelectInput
                        label='¿Continúa antibiótico por mas de 24 horas?'
                        value={antibiotic3}
                        onChange={(e)=>setAntibiotic3(e.target.value)}
                    />
                    <Input 
                        label=' Registre el tipo de antibiótico'
                        type='text'
                        value={antibioticType}  
                        onChange={(e)=>setAntibioticType(e.target.value)}
                    />
                    <Input 
                        label='Indique el destino de egreso del paciente'
                        type='text'
                        value={patientsDestiny}  
                        onChange={(e)=>setPatientsDestiny(e.target.value)}
                    />
                    <h4>Según sea el caso, ingrese el tipo de admisión H o A</h4>
                    <select className='antibioticos--container__select' value={admisionType}  onChange={(e)=>setAdmisionType(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="A">A</option>
                        <option value="H">H</option>
                    </select>
                    <Input 
                        label='Seleccione tipo de procedimiento Qx IAAS'
                        type='text'
                        value={procedure}  
                        onChange={(e)=>setProcedure(e.target.value)}
                    />
                    <Input 
                        label='Nombres y apellidos del Cirujano'
                        type='text'
                        value={surgeonName}  
                        onChange={(e)=>setSurgeonName(e.target.value)}
                    />
                    <Input 
                        label='Nombres y apellidos del Segundo cirujano'
                        type='text'
                        value={surgeonName2}  
                        onChange={(e)=>setSurgeonName2(e.target.value)}
                    />
                    <Input 
                        label='Fecha del Procedimiento'
                        type='date'
                        value={procedureDate}  
                        onChange={(e)=>setProcedureDate(e.target.value)}
                    />
                    <Input 
                        label='Registre el número de la sala'
                        type='text'
                        value={room}  
                        onChange={(e)=>setRoom(e.target.value)}
                    />
                    <Input 
                        label='Duración (en minutos)'
                        type='number'
                        value={duration}  
                        onChange={(e)=>setDuration(e.target.value)}
                    />
                    <SelectInput
                         label='¿Se presentó sangrado Intra Operatorio?'
                         value={blood}  
                         onChange={(e)=>setBlood(e.target.value)}
                    />
                    <SelectInput
                         label='¿Transfusión Intra Operatorio?'
                         value={transfusion}  
                         onChange={(e)=>setTransfusion(e.target.value)}
                    />
                    <SelectInput
                         label='¿Material Extraño?'
                         value={weirdMaterial}  
                         onChange={(e)=>setWeirdMaterial(e.target.value)}
                    />
                    <SelectInput
                         label='¿Rasurado(a) en salas?'
                         value={shave}  
                         onChange={(e)=>setShave(e.target.value)}
                    />
                    <h4>Tipo de herida quirúrgica</h4>
                    <select className='antibioticos--container__select' value={woundType}  onChange={(e)=>setWoundType(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Limpia">Limpia</option>
                        <option value="Limpia contanimada">Limpia contanimada</option>
                    </select>
                    <h4>Tipo de procedimiento quirurgíco</h4>
                    <select className='antibioticos--container__select' value={procedureType}  onChange={(e)=>setProcedureType(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="A">A</option>
                        <option value="H">H</option>
                    </select>
                    <h4>Indique la clasificación del riesgo anestésico</h4>
                    <select className='antibioticos--container__select' value={risk}  onChange={(e)=>setRisk(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="III">III</option>
                        <option value="II">II</option>
                    </select>
                    <SelectInput
                         label='Clasificación NNIS - Parámetros'
                         value={clasification}  
                         onChange={(e)=>setClasification(e.target.value)}
                    />
                    <h4>ASA 3, 4, 5</h4>
                    <select className='antibioticos--container__select' value={asa}  onChange={(e)=>setAsa(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="0">0</option>
                        <option value="1">1</option>
                    </select>
                    <h4>Cirugía mayor o igual de 2 horas</h4>
                    <select className='antibioticos--container__select' value={surgery2}  onChange={(e)=>setSurgery2(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="0">0</option>
                        <option value="1">1</option>
                    </select>
                    <h4>Herida contaminada o sucia</h4>
                    <select className='antibioticos--container__select' value={wound2}  onChange={(e)=>setWound2(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="0">0</option>
                        <option value="1">1</option>
                    </select>
                    <Input 
                        label='Total puntaje NNIS'
                        type='text'
                        value={score}  
                        onChange={(e)=>setScore(e.target.value)}
                    />
                    <h4>Seleccione el tipo de dispositivo</h4>
                    <select className='antibioticos--container__select' value={device}  onChange={(e)=>setDevice(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Catéter central">Catéter central</option>
                        <option value="Catéter periférico">Catéter periférico</option>
                        <option value="Otro">Otro</option>
                    </select>
                    <Input 
                        label='Tipo de catéter'
                        type='text'
                        value={cateter}  
                        onChange={(e)=>setCateter(e.target.value)}
                    />
                    <Input 
                        label='Lugar paso de catéter'
                        type='text'
                        value={cateterPlace}  
                        onChange={(e)=>setCateterPlace(e.target.value)}
                    />
                    <Input 
                        label='Fecha de inserción'
                        type='date'
                        value={insertionDate}  
                        onChange={(e)=>setInsertionDate(e.target.value)}
                    />
                    <Input 
                        label='Fecha de retiro'
                        type='date'
                        value={retireDate}  
                        onChange={(e)=>setRetireDate(e.target.value)}
                    />
                    <Input 
                        label='Total dias'
                        type='number'
                        value={total}  
                        onChange={(e)=>setTotal(e.target.value)}
                    />
                    <h4>Estado egreso del paciente</h4>
                    <select className='antibioticos--container__select' value={egreso}  onChange={(e)=>setEgreso(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Vivo">Vivo</option>
                        <option value="Muerto">Muerto</option>
                    </select>
                    <SelectInput
                         label='¿La muerte es asociada a la infección?'
                         value={death}  
                         onChange={(e)=>setDeath(e.target.value)}
                    />
                    <SelectInput
                         label='¿El evento es prevenible?'
                         value={preventable}  
                         onChange={(e)=>setPreventable(e.target.value)}
                    />
                    <Input 
                        label='Estancia por infección (en días, meses o años)'
                        type='text'
                        value={estancia}  
                        onChange={(e)=>setEstancia(e.target.value)}
                    />
                    <Input 
                        label='Estancia previa a la infección (en días, meses o años)'
                        type='text'
                        value={estancia2}  
                        onChange={(e)=>setEstancia2(e.target.value)}
                    />
                    <h4>Tipo de búsqueda</h4>
                    <select className='antibioticos--container__select' value={searchType}  onChange={(e)=>setSearchType(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Activa">Activa</option>
                        <option value="Pasiva">Pasiva</option>
                    </select>
                    <Input 
                        label='Nombres completo el/la Enfermero/a que diligencia el formato'
                        type='text'
                        value={nurseName}  
                        onChange={(e)=>setNurseName(e.target.value)}
                    />
                    <Input 
                        label='Nombre del infectologo'
                        type='text'
                        value={infectologistName}  
                        onChange={(e)=>setInfectologistaName(e.target.value)}
                    />
                    <SelectInput
                         label='¿IAAS Confirmada?'
                         value={iaas}  
                         onChange={(e)=>setIaas(e.target.value)}
                    />
                    <div className="casos--container__btn">
                        <Button
                            title='Enviar'
                        />
                     </div>
                   </form>
                </div>
            </div>
            <Navbar/>
        </div>
    )
}

export default Casos
