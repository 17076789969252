import React from 'react';
import './Topbar.scss';
import { Link, useHistory } from 'react-router-dom';
import { BiChevronLeft } from "react-icons/bi";
import MainLogo from '../../assets/brand/eye.png'

function Topbar() {
    const history = useHistory();

    const handleHistory = () => {
        history.goBack();
    }

    return (
        <div className='topbar' >
            <header className='topbar--container'>
                <BiChevronLeft size='30px' color='#666bcd' onClick={handleHistory}/>
                <Link to='/' style={{textDecoration: 'none'}}>
                    <img src={MainLogo} className='topbar--container__img' alt=""/>
                </Link>
            </header>
        </div>
    )
}

export default Topbar
