import React from 'react';
import './TextArea.scss'

function TextArea({ label, name, value, cols, rows, onChange, type,   }) {
    return (
        <div className='container'>
            <p>{label}</p>
            <textarea name={name} value={value} cols={cols} rows={rows} onChange={onChange}>
            </textarea>
        </div>
    )
}

export default TextArea
