import React from 'react';
import './Profile.scss';
import { app } from '../../firebase/firebaseConfig';
import { BiLocationPlus, BiUser, BiPhone, BiBuilding, BiPlusMedical} from "react-icons/bi";
import Topbar from '../../components/Topbar/Topbar';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import Navbar from '../../components/Navbar/Navbar';

function Profile() {

    const currentUser = app.auth().currentUser.email

    const data = [
        {
            key: '1',
            icon: <BiUser/>,
            name: currentUser
        },
        {
            key: '2',
            icon: <BiPlusMedical/>,
            name: 'Centro Cardiovascular CO.'
        },
        {
            key: '3',
            icon: <BiBuilding/>,
            name: 'Carrera 71B No. 51-09'
        },
        {
            key: '4',
            icon: <BiLocationPlus/>,
            name: 'Bogotá'
        },
        {
            key: '5',
            icon: <BiPhone/>,
            name: '(+571) 328 57 97'
        }
    ]
 
     const handleSignOut = () => {
         app.auth().signOut()
         .then(()=> {
             console.log('no hay usuario activo')
         }) 
     }

    return (
        <div className='profile'>
            <Topbar/> 
            <h2>Perfil</h2>
            <div className="profile--container">
                {
                    data.map((data => 
                        <ProfileCard
                            key={data.key}
                            icon={data.icon}
                            name={data.name}
                        />
                        ))
                }
            </div>
            <div className="profile--container__btn">
                <button  className='profile--btn' onClick={handleSignOut}>Cerrar sesión</button>
            </div>   
            <Navbar/>
        </div>
    )
}

export default Profile
