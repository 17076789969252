import React from 'react';
import './CheckBox.scss'

function CheckBox({ value, onChange, label }) {
    return (
        <div className='checkbox'>
            <label><input type="checkbox" id="cbox1" value={value} onChange={onChange}/> <br /> {label}</label>
        </div>
    )
}

export default CheckBox
