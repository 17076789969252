import React from 'react';
import './Button.scss';

function Button ({ type, title, onClick, className }) {
    return (
    <>
        <div className="button">
            <button
                type={type}
                className={className}
                onClick={onClick}
            >
                {title}
            </button>
         
        </div>
    </>
    )
}

export default Button