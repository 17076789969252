import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Cirugia.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import  MonthsInput from '../../../components/MonthsInput/MonthsInput';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';

function Cirugia() {
    //get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //useHistory Hook
    const history = useHistory();

    //data to get captured
    const [date, setDate] = useState('');
    const [infeccion1, setInfeccion1] = useState('');
    const [infeccion2, setInfeccion2] = useState('');
    const [herida1, setHerida1] = useState('');
    const [herida2, setHerida2] = useState('');
    const [herida3, setHerida3] = useState('');
    const [herida4, setHerida4] = useState('');
    const [herida5, setHerida5] = useState('');
    const [herida6, setHerida6] = useState('');
    const [herida7, setHerida7] = useState('');
    const [herida8, setHerida8] = useState('');
    const [herida9, setHerida9] = useState('');
    const [herida10, setHerida10] = useState('');

    const data = [
        {
            key: '1',
            label: '1. Infecciones de sitio operatorio (ISO)',
            placeholder: 'Número de ISO',
            value: infeccion1,
            onChange: (e)=>setInfeccion1(e.target.value)
        },
        {
            key: '2',
            label: '',
            placeholder: 'Número de procedimientos quirúrgicos',
            value: infeccion2,
            onChange: (e)=>setInfeccion2(e.target.value)
        },
        {
            key: '3',
            label: '2. Proporción de infección de sitio operatorio en herida limpia',
            placeholder: 'Número de ISO de herida limpia',
            value: herida1,
            onChange: (e)=>setHerida1(e.target.value)
        },
        {
            key: '4',
            label: '',
            placeholder: 'Total procedimientos herida limpia',
            value: herida2,
            onChange: (e)=>setHerida2(e.target.value)
        },
        {
            key: '5',
            label: '4. Proporción de infección de sitio operatorio en herida limpia contaminada (LC)',
            placeholder: 'Número de  de ISO herida LC',
            value: herida3,
            onChange: (e)=>setHerida3(e.target.value)
        },
        {
            key: '6',
            label: '',
            placeholder: 'Total procedimientos herida LC',
            value: herida4,
            onChange: (e)=>setHerida4(e.target.value)
        },
        {
            key: '7',
            label: '5. Tasa infecciones de sitio operatorio pos cesaera',
            placeholder: 'Número de ISO por cesárea',
            value: herida5,
            onChange: (e)=>setHerida5(e.target.value)
        },
        {
            key: '8',
            label: '',
            placeholder: 'Total cesáreas realizadas',
            value: herida6,
            onChange: (e)=>setHerida6(e.target.value)
        },
        {
            key: '9',
            label: '6. Emdometritis por parto',
            placeholder: 'Número de endometritis por parto',
            value: herida7,
            onChange: (e)=>setHerida7(e.target.value)
        },
        {
            key: '10',
            label: '',
            placeholder: 'Total partos realizados',
            value: herida8,
            onChange: (e)=>setHerida8(e.target.value)
        },
        {
            key: '11',
            label: '6. Emdometritis por cesárea',
            placeholder: 'Número de endometritis por cesárea',
            value: herida9,
            onChange: (e)=>setHerida9(e.target.value)
        },
        {
            key: '12',
            label: '',
            placeholder: 'Total cesáreas realizados',
            value: herida10,
            onChange: (e)=>setHerida10(e.target.value)
        },
    ]
    //send data to firestore    
    const handleSubmit = (e) => {
        e.preventDefault();
        if(date.lenght === 0 ||infeccion1.lenght === 0 ||
            infeccion2.lenght === 0 || herida1.lenght === 0 || 
            herida2.length === 0 || herida3.lenght === 0 || herida4.lenght === 0 || 
            herida5.length === 0 || herida6.lenght === 0 || herida7.length === 0 || 
            herida8.lenght === 0 || herida9.lenght === 0 || herida10.lenght === 0) {
                swal({
                    title: 'Cuidado',
                    text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                    icon: 'warning',
                    button: 'Volver'
                });

        } else {
            db.collection('Cirugia y partos').add({
                user: currentUser,
                currentDate: currentDate,
                date: date,
                infeccion1: infeccion1,
                infeccion2: infeccion2,
                herida1: herida1,
                herida2: herida2,
                herida3: herida3,
                herida4: herida4,
                herida5: herida5,
                herida6: herida6,
                herida7: herida7,
                herida8: herida8,
                herida9: herida9,
                herida10: herida10,
            })
            .then(() => {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                })
            })
            .then(function () {
                history.goBack();
           })
            .catch((error) => {
                console.log(error)  
            })
        }  
    }

    return (
        <div className='cirugia'>   
            <Topbar/>
                <h2>Cirugía y Partos</h2>
            <div className="cirugia--container">
                <MonthsInput
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                {
                    data.map(data => 
                            <Input
                                key={data.key}
                                label={data.label}
                                placeholder={data.placeholder}
                                value={data.value}
                                onChange={data.onChange}
                            />
                        )
                }
                <div className="cirugia--container__form">
                    <form action="" onSubmit={handleSubmit}>
    
                        <div className="cirugia--container__btn">
                            <Button
                                title='Enviar'
                            />
                        </div>
                    </form>
                </div>
            </div>
            <Navbar/>
        </div>
    )
}

export default Cirugia
