import React, { useState } from 'react';
import './SeguimientoCateter.scss';
import swal from 'sweetalert';
import { app, db } from '../../../firebase/firebaseConfig';
import { useHistory } from 'react-router-dom';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';


function SeguimientoCateter() {

    //get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    
    //history hook
    const history = useHistory();

    //set state for data
    const [date, setDate] = useState('');
    const [seguimiento1, setSeguimiento1] = useState('');
    const [seguimiento2, setSeguimiento2] = useState('');
    const [seguimiento3, setSeguimiento3] = useState('');
    const [seguimiento4, setSeguimiento4] = useState('');

    //data
    const data = [
        {
            key: '1',
            label: '1. El apósito transparente esta integro',
            value: seguimiento1,
            onChange: (e) => setSeguimiento1(e.target.value)
        },
        {
            key: '2',
            label: '2. Se evidencia signos de infección.',
            value: seguimiento2,
            onChange: (e) => setSeguimiento2(e.target.value)

        },
        {
            key: '3',
            label: '3. Hay indicación de mantener cateter',
            value: seguimiento3,
            onChange: (e) => setSeguimiento3(e.target.value)

        },
        {
            key: '4',
            label: '4. Se realiza el retiro oportuno de cateter',
            value: seguimiento4,
            onChange: (e) => setSeguimiento4(e.target.value)

        },
    ]


    //send data to firestore
    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('Vigilancia y seguimiento diario Cateter').add({
            currentUser: currentUser,
            currentDate: currentDate,
            date: date,
            seguimiento1: seguimiento1,
            seguimiento2: seguimiento2,
            seguimiento3: seguimiento3,
            seguimiento4: seguimiento4,
        })
        .then(() => {
            swal({
                title: '¡Exito!',
                text: 'Los datos han sido enviados exitosamente',
                icon: 'success',
                button: 'Seguir'
            });
        })
        .then(() => {
            history.goBack();
        })
        .catch((error) => {
            console.log(error)
        })
    }
    return (
        <div className='Container'>
            <Topbar/>
            <h2>Vigilancia y seguimiento diario Catéter</h2>
            <div className="form"onSubmit={handleSubmit}>
                <form action="" >
                    <Input
                        label='fecha'
                        type='date'
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                    />
                    <Input
                        label='Nombre paciente'
                        type='date'
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                    />
                    <Input
                        label='Número de identificación'
                        type='text'
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                    />
                    {
                        data.map(data => 
                            <SelectInput
                                key={data.key}
                                label={data.label}
                                value={data.value}
                                onChange={data.onChange}
                            />
                        )
                    }
                    <div className="form__btn">
                        <Button
                            title='Enviar'
                        />
                    </div>
                </form>
            </div>
            <Navbar/> 
        </div>
    )
}

export default SeguimientoCateter;
