import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Neonatal.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import  MonthsInput from '../../../components/MonthsInput/MonthsInput';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';

function Neonatal() {
    //get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //useHistory Hook
    const history = useHistory();

    //data to get captured
    const [date, setDate] = useState('');
    const [neo1, setNeo1] = useState('');
    const [neo2, setNeo2] = useState('');
    const [neo3, setNeo3] = useState('');
    const [neo4, setNeo4] = useState('');
    const [neo5, setNeo5] = useState('');
    const [neo6, setNeo6] = useState('');
    const [neo7, setNeo7] = useState('');
    const [neo8, setNeo8] = useState('');
    const [neo9, setNeo9] = useState('');
    const [neo10, setNeo10] = useState('');
    const [neo11, setNeo11] = useState('');
    const [neo12, setNeo12] = useState('');
    const [neo13, setNeo13] = useState('');
    const [neo14, setNeo14] = useState('');
    const [neo15, setNeo15] = useState('');
    const [neo16, setNeo16] = useState('');
    const [neo17, setNeo17] = useState('');
    const [neo18, setNeo18] = useState('');
    const [neo19, setNeo19] = useState('');
    const [neo20, setNeo20] = useState('');
    const [neo21, setNeo21] = useState('');
    const [neo22, setNeo22] = useState('');
   
    const data = [
        {
            key: '1',
            label: '1. Tasa de infección UCI neonatal',
            placeholder: 'Número de IAAS',
            value: neo1,
            onChange: (e)=>setNeo1(e.target.value)
        },
        {
            key: '2',
            label: '',
            placeholder: 'Total días estancia',
            value: neo2,
            onChange: (e)=>setNeo2(e.target.value)
        },
        {
            key: '3',
            label: '2. Tasa de infección del torrente sanguineo asociado a cateter central (ITS-AC) < 750 gr',
            placeholder: 'Número de IST-AC < 750 gr',
            value: neo3,
            onChange: (e)=>setNeo3(e.target.value)
        },
        {
            key: '4',
            label: '',
            placeholder: 'Total días catéter central < 750',
            value: neo4,
            onChange: (e)=>setNeo4(e.target.value)
        },
        {
            key: '5',
            label: '3. Tasa de infección del torrente sanguineo asociado a cateter central (ITS-AC) 750 gr - 1,000 gr',
            placeholder: 'Número de IST-AC 750 gr - 1,000 gr',
            value: neo5,
            onChange: (e)=>setNeo5(e.target.value)
        },
        {
            key: '6',
            label: '',
            placeholder: 'Total días catéter central 750 gr - 1,000 gr',
            value: neo6,
            onChange: (e)=>setNeo6(e.target.value)
        },
        {
            key: '7',
            label: '4. Tasa de infección del torrente sanguineo asociado a cateter central (ITS-AC) 1,001 gr - 1,500 gr',
            placeholder: 'Número de IST-AC 1,001 gr - 1,500 gr',
            value: neo7,
            onChange: (e)=>setNeo7(e.target.value)
        },
        {
            key: '8',
            label: '',
            placeholder: 'Total días catéter central 1,001 gr - 1,500 gr',
            value: neo8,
            onChange: (e)=>setNeo8(e.target.value)
        },
        {
            key: '9',
            label: '5. Tasa de infección del torrente sanguineo asociado a cateter central (ITS-AC) 1,501 gr - 2,500 gr',
            placeholder: 'Número de IST-AC 1,501 gr - 2,500 gr',
            value: neo9,
            onChange: (e)=>setNeo9(e.target.value)
        },
        {
            key: '10',
            label: '',
            placeholder: 'Total días catéter central 1,501 gr - 2,500 gr',
            value: neo10,
            onChange: (e)=>setNeo10(e.target.value)
        },
        {
            key: '11',
            label: '6. Tasa de infección del torrente sanguineo asociado a cateter central (ITS-AC) 2,500 gr >',
            placeholder: 'Número de IST-AC 2,500 gr >',
            value: neo11,
            onChange: (e)=>setNeo11(e.target.value)
        },
        {
            key: '12',
            label: '',
            placeholder: 'Total días catéter central 2,500 gr >',
            value: neo12,
            onChange: (e)=>setNeo12(e.target.value)
        },
        {
            key: '13',
            label: '7. Tasa de infección en ventilación mecánica (NAV) < 750 gr',
            placeholder: 'Número de NAV',
            value: neo13,
            onChange: (e)=>setNeo13(e.target.value)
        },
        {
            key: '14',
            label: '',
            placeholder: 'Total días ventilación mecánica < 750 gr',
            value: neo14,
            onChange: (e)=>setNeo14(e.target.value)
        },
        {
            key: '15',
            label: '8. Tasa de infección en ventilación mecánica (NAV) 751 gr - 1,000 gr',
            placeholder: 'Número de NAV 751 gr - 1,000 gr',
            value: neo15,
            onChange: (e)=>setNeo15(e.target.value)
        },
        {
            key: '16',
            label: '',
            placeholder: 'Total días ventilación mecánica 751 gr - 1,000 gr',
            value: neo16,
            onChange: (e)=>setNeo16(e.target.value)
        },
        {
            key: '17',
            label: '9. Tasa de infección en ventilación mecánica (NAV) 1,001 gr - 1,500 gr',
            placeholder: 'Número de NAV 1,001 gr - 1,500 gr',
            value: neo17,
            onChange: (e)=>setNeo17(e.target.value)
        },
        {
            key: '18',
            label: '',
            placeholder: 'Total días ventilación mecánica 1,001 gr - 1,500 gr',
            value: neo18,
            onChange: (e)=>setNeo18(e.target.value)
        },
        {
            key: '19',
            label: '10. Tasa de infección en ventilación mecánica (NAV) 1,501 gr - 2,500 gr',
            placeholder: 'Número de NAV 1,501 gr - 2,500 gr',
            value: neo19,
            onChange: (e)=>setNeo19(e.target.value)
        },
        {
            key: '20',
            label: '',
            placeholder: 'Total días ventilación mecánica 1,501 gr - 2,500 gr',
            value: neo20,
            onChange: (e)=>setNeo20(e.target.value)
        },
        {
            key: '21',
            label: '11. Tasa de infección en ventilación mecánica (NAV) 2,500 gr >',
            placeholder: 'Número de NAV 1,501 gr - 2,500 gr',
            value: neo21,
            onChange: (e)=>setNeo21(e.target.value)
        },
        {
            key: '22',
            label: '',
            placeholder: 'Total días ventilación mecánica 2,500 gr >',
            value: neo22,
            onChange: (e)=>setNeo22(e.target.value)
        },
    ]


    //send data to firestore    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (date.length === 0 || neo1.length === 0 || neo1.length === 0 
            || neo2.length === 0 || neo3.length === 0 || neo4.length === 0 
            || neo5.length === 0 || neo6.length === 0 || neo7.length === 0 
            || neo8.length === 0 || neo9.length === 0 || neo10.length === 0 
            || neo11.length === 0 || neo12.length === 0 || neo13.length === 0 
            || neo14.length === 0 || neo15.length === 0 || neo16.length === 0 
            || neo17.length === 0 || neo18.length === 0 || neo19.length === 0 
            || neo20.length === 0 || neo21.length === 0 || neo22.length === 0 ) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('Unidad Neonatal').add({
                user: currentUser,
                currentDate: currentDate,
                date: date,
                neo1: neo1,
                neo2: neo2,
                neo3: neo3,
                neo4: neo4,
                neo5: neo5,
                neo6: neo6,
                neo7: neo7,
                neo8: neo8,
                neo9: neo9,
                neo10: neo10,
                neo11: neo11,
                neo12: neo12,
                neo13: neo13,
                neo14: neo14,
                neo15: neo15,
                neo16: neo16,
                neo17: neo17,
                neo18: neo18,
                neo19: neo19,
                neo20: neo20,
                neo21: neo21,
                neo22: neo22
            })
            .then(() => {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                })
            })
            .then(function () {
                history.goBack();
           })
            .catch((error) => {
                console.log(error)  
            })
        }
      
    }

    return (
        <div className='cirugia'>   
            <Topbar/>
                <h2>Unidad Neonatal</h2>
            <div className="cirugia--container">
                <MonthsInput
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                {
                    data.map(data => 
                            <Input
                                key={data.key}
                                label={data.label}
                                placeholder={data.placeholder}
                                value={data.value}
                                onChange={data.onChange}
                            />
                        )
                }
                <div className="cirugia--container__form">
                    <form action="" onSubmit={handleSubmit}>
    
                        <div className="cirugia--container__btn">
                            <Button
                                title='Enviar'
                            />
                        </div>
                    </form>
                </div>
            </div>
            <Navbar/>
        </div>
    )
}

export default Neonatal
