import React, { useState } from 'react';
import './Periferico.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import HandImg from '../../../assets/images/hand.png'
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';



function Periferico() {

    //get user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();

    //get history
    const history = useHistory();

    //set state for data
    const [name, setName] = useState('');
    const [register, setRegister] = useState('');
    const [age, setAge] = useState('');
    const [idType, setIdType] = useState('');
    const [id, setId] = useState('');
    const [bed, setBed] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [diagnostic, setDiagnostic] = useState('');

    const [insertionDate, setinsertionDate] = useState('');
    const [hour, setHour] = useState('');
    const [cateter, setCateter] = useState('');
    const [punciones, setPunciones] = useState('');
    const [infusion, setInfusion] = useState('');
    const [tapon, setTapon] = useState('');
    const [antibiotic, setAntibiotic] = useState('');
    const [antibiotic2, setAntibiotic2] = useState('');
    const [responsable, setResponsable] = useState('');
    const [servicio, setServicio] = useState('');

    const [hand, setHand] = useState('');
    const [hand2, setHand2] = useState('');
    const [retireDay, setRetireDay] = useState('');
    const [retireHour, setRetireHour] = useState('');
    const [motive, setMotive] = useState('');
    const [other, setOther] = useState('');
    
    //data
    const data = [
        {
            key: '1',
            label: 'Nombre completo del paciente',
            type: 'text',
            value: name,
            onChange: (e) => setName(e.target.value)
        },
        {
            key: '2',
            label: 'Registro',
            type: 'text',
            value: register,
            onChange: (e) => setRegister(e.target.value)
        },
        {
            key: '3',
            label: 'Edad',
            type: 'number',
            value: age,
            onChange: (e) => setAge(e.target.value)
        },
        {
            key: '3.5',
            label: 'Tipo de Documento',
            type: 'text',
            value: idType,
            onChange: (e) => setIdType(e.target.value)
        },
        {
            key: '4',
            label: 'Identificación',
            type: 'number',
            value: id,
            onChange: (e) => setId(e.target.value)
        },
        // {
        //     key: '5',
        //     label: 'Cama',
        //     type: 'text',
        //     value: bed,
        //     onChange: (e) => setBed(e.target.value)
        // },
        {
            key: '6',
            label: 'Fecha de ingreso',
            type: 'date',
            value: entryDate,
            onChange: (e) => setEntryDate(e.target.value)
        },
        {
            key: '7',
            label: 'Diagnóstico',
            type: 'text',
            value: diagnostic,
            onChange: (e) => setDiagnostic(e.target.value)
        },
    ]

    const data2 = [
        {
            key: '1',
            label: 'Fecha de inserción',
            type: 'date',
            value: insertionDate,
            onChange: (e) => setinsertionDate(e.target.value)
        },
        {
            key: '2',
            label: 'Hora',
            type: 'time',
            value: hour,
            onChange: (e) => setHour(e.target.value)
        },
        {
            key: '4',
            label: 'Catéter No.',
            type: 'text',
            value: cateter,
            onChange: (e) => setCateter(e.target.value)
        },
        {
            key: '5',
            label: 'No. de punciones',
            type: 'text',
            value: punciones,
            onChange: (e) => setPunciones(e.target.value)
        },
        {
            key: '6',
            label: 'Infusión continua',
            type: 'text',
            value: infusion,
            onChange: (e) => setInfusion(e.target.value)
        },
        {
            key: '7',
            label: 'Tapón libre de aguja',
            type: 'text',
            value: tapon,
            onChange: (e) => setTapon(e.target.value)
        },
    ]

    const handleSumbit = (e) => {
        e.preventDefault();
        if (name.length === 0 || register.length === 0 || age.length === 0 || idType.length === 0 || 
            id.length === 0 || bed.length === 0 || entryDate.length === 0 || diagnostic.length === 0 || 
            insertionDate.length === 0 || hour.length === 0 || cateter.length === 0 || punciones.length === 0
            || infusion.length === 0 || tapon.length === 0 || antibiotic.length === 0 || antibiotic2.length === 0
            || responsable.length === 0 || servicio.length === 0 ) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            })
        } else {
            db.collection('Inserción y seguimiento cateterismo periférico').add({
                currentUser: currentUser,
                currentDate: currentDate,
                name: name,
                register: register,
                age: age,
                idType: idType,
                id: id,
                bed: bed,
                entryDate: entryDate,
                diagnostic: diagnostic,
                insertionDate: insertionDate,
                hour: hour,
                cateter: cateter,
                punciones: punciones,
                infusion: infusion,
                tapon: tapon,
                antibiotic: antibiotic,
                antibiotic2: antibiotic2,
                responsable: responsable,
                servicio: servicio,
                hand: hand,
                hand2: hand2,
                retireDay: retireDay,
                retireHour: retireHour,
                motive: motive,
                other: other
            })
            .then(() =>{
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(() =>{
                history.goBack();
            })
            .catch((error) => console.log(error))
        }
    }

    return (
        <div className='periferico'>
            <Topbar/>
            <h2>Inserción y seguimiento cateterismo periférico</h2>
            <h2>Identificación del paciente</h2>
            <div className="periferico--container">
                <form action="" onSubmit={handleSumbit}>
                    {
                        data.map(data => 
                            <Input
                                key={data.key}
                                label={data.label}
                                type={data.type}
                                value={data.value}
                                onChange={data.onChange}
                            />
                            )
                    }
                         <h4>Servicio</h4>
                      <select className='antibioticos--container__select'   value={servicio} onChange={(e) => setServicio(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Consulta prioritaria">Consulta prioritaria</option>
                        <option value="Radiología">Radiología</option>
                        <option value="Hospitalización">Hospitalización</option>
                        <option value="UCI intermedio adultos">UCI intermedio adultos</option>
                        <option value="Preparto">Preparto</option>
                        <option value="Recuperación">Recuperación</option>
                        <option value="Uci neonatal">UCI neonatal</option>
                    </select>
                    <h4>Cama/habitación</h4>
                    <select name="select_1"  value={bed} onChange={(e) => setBed(e.target.value)}>
                        <optgroup label="Consulta Prioritaria">
                            <option hidden defaultValue value="0">Selecciona una opción</option>
                            <option value="Consultorio 1">Consultorio 1</option>
                            <option value="Consultorio 2">Consultorio 2</option>
                        </optgroup>
                        <optgroup label="Radiología">
                            <option value="Rayos X">Rayos X</option>
                        </optgroup>
                        <optgroup label="Hospitalización">
                            <option value="101">101</option>
                            <option value="102A">102A</option>
                            <option value="102B">102B</option>
                            <option value="103A">103A</option>
                            <option value="103B">103B</option>
                            <option value="103C">103C</option>
                            <option value="103D">103D</option>
                            <option value="104A">104A</option>
                            <option value="104B">104B</option>
                            <option value="105A">105A</option>
                            <option value="105A">105B</option>
                            <option value="106A">106A</option>
                            <option value="106B">106B</option>
                            <option value="106C">106C</option>
                            <option value="106D">106D</option>
                            <option value="107A">107A</option>
                            <option value="107B">107B</option>
                            <option value="108A">108A</option>
                            <option value="108B">108B</option>
                        </optgroup>
                        <optgroup label="UCI Intermedios adulto">
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                        </optgroup>
                        <optgroup label="Preparto">
                            <option value="PRE01">PRE01</option>
                            <option value="PREO2">PREO2</option>
                            <option value="PRE03">PRE03</option>
                            <option value="PRE04">PRE04</option>
                            <option value="PRE05">PRE05</option>
                            <option value="PRE06">PRE06</option>
                            <option value="PRE07">PRE07</option>
                            <option value="PRE08">PRE08</option>
                            <option value="PRE09">PRE09</option>
                            <option value="PRE10">PRE10</option>
                            <option value="Sala de parto 1">Sala de parto 1</option>
                            <option value="Sala de parto 2">Sala de parto 2</option>
                            <option value="Quirofano 1">Quirofano 1</option>
                            <option value="Quirofano 2">Quirofano 2</option>
                        </optgroup>
                        <optgroup label="Recuperación">
                            <option value="REC01">REC01</option>
                            <option value="REC02">REC02</option>
                            <option value="REC03">REC03</option>
                            <option value="REC04">REC04</option>
                            <option value="REC05">REC05</option>
                            <option value="REC06">REC06</option>
                        </optgroup>
                        <optgroup label="Unidad neonatal">
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                            <option value="39">39</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="45">45</option>
                            <option value="46">46</option>
                            <option value="47">47</option>
                            <option value="48">48</option>
                            <option value="49">49</option>
                            <option value="50">50</option>
                        </optgroup>
                    </select>
                    <h2>Acceso venoso periférico</h2>
                    {
                        data2.map(data2 => 
                                <Input
                                    key={data2.key}
                                    label={data2.label}
                                    type={data2.type}
                                    value={data2.value}
                                    onChange={data2.onChange}
                                />
                            )
                    }
                    
                    <h4>Manejo de antibiótico</h4>
                    <select className='antibioticos--container__select' value={antibiotic}  onChange={(e) => setAntibiotic(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                    </select>
                    <Input
                        label='Si escogiste otro, especifica cuál'
                        type='text'
                        value={antibiotic2}
                        onChange={(e) => setAntibiotic2(e.target.value)}
                    />
                    <Input
                        label='Responsable del procedimiento'
                        type='text'
                        value={responsable}
                        onChange={(e) => setResponsable(e.target.value)}
                    />
                    <h2>Inserción</h2>
                    <div className="periferico--container__img">
                        <img src={HandImg} alt=""/>
                    </div>
                    <h4>Miembro Superior Derecho</h4>
                    <p>Vía anterior (A)</p>
                    <select className='antibioticos--container__select' value={hand}  onChange={(e) => setHand(e.target.value)}>
                    <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="No aplica">No aplica</option>
                        <option value="1. V. Cefálica superior">1. V. Cefálica superior</option>
                        <option value="2. V. Basílica superior">2. V. Basílica superior</option>
                        <option value="3. V. Mediana basílica">3. V. Mediana basílica</option>
                        <option value="4. V. Mediana cefálica">4. V. Mediana cefálica</option>
                        <option value="5. V. Cefálica inferior">5. V. Cefálica inferior</option>
                        <option value="6. V. Basílica inferior">6. V. Basílica inferior</option>
                        <option value="7. V. Mediana antebrazo">7. V. Mediana antebrazo</option>
                        <option value="8. V. Cefálica accesoria">8. V. Cefálica accesoria</option>
                    </select>
                    <h4>Miembro Superior Izquierdo</h4>
                    <p>Vía posterior (P)</p>
                    <select className='antibioticos--container__select' value={hand2}  onChange={(e) => setHand2(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="No aplica">No aplica</option>
                        <option value="1. V. Cefálica">1. V. Cefálica</option>
                        <option value="2. V. Basílica inferior">2. V. Basílica inferior</option>
                        <option value="3. V. Cefálica inferior">3. V. Cefálica inferior</option>
                        <option value="4. V. Cefálica accesoria">4. V. Cefálica accesoria</option>
                        <option value="5. V. Basílica">5. V. Basílica</option>
                        <option value="6. Arco venoso dorsal">6. Arco venoso dorsal</option>
                        <option value="7. V. Metacarpianas">7. V. Metacarpianas</option>
                    </select>
                    <Input
                        label='Fecha de retiro'
                        type='date'
                        value= {retireDay}
                        onChange={(e) => setRetireDay(e.target.value)}

                    />
                    <Input
                        label='Hora'
                        type='time'
                        value= {retireHour}
                        onChange={(e) => setRetireHour(e.target.value)}
                    />
                    <h2>Motivo del Retiro</h2>
                    <select className='antibioticos--container__select' value={motive} onChange={(e)=>setMotive(e.target.value)}>
                        <option hidden defaultValue>Selecciona una opción</option>  
                        <option value="Flebitis (criterio)">Flebitis (criterio)</option>
                        <option value="Hematoma">Hematoma</option>
                        <option value="Cambio de cateter central">Cambio de cateter central</option>
                        <option value="Disfunción mecánica">Disfunción mecánica</option>
                        <option value="Infiltración">Infiltración</option>
                        <option value="Otro">Otro</option>
                    </select>
                    <Input
                        label='Si escogiste otro, especifica'
                        type='text'
                        value={other}
                        onChange={(e)=>setOther(e.target.value)}
                    />
                    <div className="periferico--container__btn">
                        <Button
                            title='Enviar'
                        />
                    </div>
                </form>
            </div>
            <Navbar/>   
        </div>
    )
}

export default Periferico
