import React, { useState } from 'react';
import './Uci.scss';
import { useHistory } from 'react-router-dom';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import MonthsInput from '../../../components/MonthsInput/MonthsInput';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';


function Uci() {

    //Here we get the current user from Firebase
    const currentUser = app.auth().currentUser.email;
    //get current day and hour
    const currentDay = new Date();

    //get history 
    const history = useHistory();

    // input fields
    const [date, setDate] = useState('');
    const [uci1, setUci1] = useState('');
    const [uci2, setUci2] = useState('');
    const [uci3, setUci3] = useState('');
    const [uci4, setUci4] = useState('');
    const [uci5, setUci5] = useState('');
    const [uci6, setUci6] = useState('');
    const [uci7, setUci7] = useState('');
    const [uci8, setUci8] = useState('');
    const [uci9, setUci9] = useState('');
    const [uci10, setUci10] = useState('');
    const [uci11, setUci11] = useState('');
    const [uci12, setUci12] = useState('');
    const [uci13, setUci13] = useState('');
    const [uci14, setUci14] = useState('');


    //Here is the data used for the inputs 
    const data = [
        {
            key: '1',
            type: 'number',
            label: '1. Porcentaje de infección asociada a la atención en salud en la Unidad de Cuidado Intensivo por egreso',
            placeholder: 'Número de casos de IAAS',
            name: '',
            className: '',
            value: uci1,
            onChange: function (e) 
            { 
                return setUci1(e.target.value) 
            } 
        },
        {
            key: '2',
            type: 'number',
            label: '',
            placeholder: 'Número de egresos',
            name: '',
            className: '',
            value: uci2,
            onChange: function (e) 
            { 
                return setUci2(e.target.value) 
            } 
        },
        {
            key: '3',
            type: 'number',
            label: '2. Tasa de infección asociada a la atención en salud en Unidad de Cuidado Intensivo',
            placeholder: 'Numero de casos de IAAS',
            name: '',
            className: '',
            value: uci3,
            onChange: function (e) 
            { 
                return setUci3(e.target.value) 
            } 
        },
        {
            key: '4',
            type: 'number',
            label: '',
            placeholder: 'Número de días paciente',
            name: '',
            className: '',
            value: uci4,
            onChange: function (e) 
            { 
                return setUci4(e.target.value) 
            } 
        },
        {
            key: '5',
            type: 'number',
            label: '3. Tasa de infección de vías urinarias asociadas a sonda vesical en la Cuidados intensivos (ISTU-AC)',
            placeholder: 'Número ISTU-AC',
            name: '',
            className: '',
            value: uci5,
            onChange: function (e) 
            { 
                return setUci5(e.target.value) 
            } 
        },
        {
            key: '6',
            type: 'number',
            label: '',
            placeholder: 'Total dias catéter Urinario',
            name: '',
            className: '',
            value: uci6,
            onChange: function (e) 
            { 
                return setUci6(e.target.value) 
            } 
        },
        {
            key: '7',
            type: 'number',
            label: '4. Infecciones asociadas a dispositivo del tracto urinario (ISTU) en UCI',
            placeholder: 'Número de ISTU',
            name: '',
            className: '',
            value: uci7,
            onChange: function (e) 
            { 
                return setUci7(e.target.value) 
            } 
        },
        {
            key: '8',
            type: 'number',
            label: '',
            placeholder: 'Número de egresos en UCI',
            name: '',
            className: '',
            value: uci8,
            onChange: function (e) 
            { 
                return setUci8(e.target.value) 
            } 
        },
        {
            key: '9',
            type: 'number',
            label: '5. Tasa neumonía asociada a la ventilación mecánica (NAV)',
            placeholder: 'Número de NAV',
            name: '',
            className: '',
            value: uci9,
            onChange: function (e) 
            { 
                return setUci9(e.target.value) 
            } 
        },
        {
            key: '10',
            type: 'number',
            label: '',
            placeholder: 'Total días ventilación mecánica',
            name: '',
            className: '',
            value: uci10,
            onChange: function (e) 
            { 
                return setUci10(e.target.value) 
            } 
        },
        {
            key: '11',
            type: 'number',
            label: '6. Tasa de Infección del torrente sanguineo asociadas a cateter central (ITS-AC)',
            placeholder: 'Número de ITS-AC',
            name: '',
            className: '',
            value: uci11,
            onChange: function (e) 
            { 
                return setUci11(e.target.value) 
            } 
        },
        {
            key: '12',
            type: 'number',
            label: '',
            placeholder: 'Total días de catéter central',
            name: '',
            className: '',
            value: uci12,
            onChange: function (e) 
            { 
                return setUci12(e.target.value) 
            } 
        },
 
        {
            key: '13',
            type: 'number',
            label: '7. Tasa de flebitis bacteriana',
            placeholder: 'Número de flebitis',
            name: '',
            className: '',
            value: uci13,
            onChange: function (e) 
            { 
                return setUci13(e.target.value) 
            } 
        },
        {
            key: '14',
            type: 'number',
            label: '',
            placeholder: 'Total días de catéter periférico',
            name: '',
            className: '',
            value: uci14,
            onChange: function (e) 
            { 
                return setUci14(e.target.value) 
            } 
        },
    ]

    //Here we send the data to firestore
    const handleSubmit = (e) => {
         e.preventDefault();
         if (date.length === 0 || uci1.length === 0 || uci2.length === 0 || 
            uci3.length === 0 || uci4.length === 0 ||uci5.length === 0 
            ||uci6.length === 0 ||uci7.length === 0 ||uci8.length === 0 
            ||uci9.length === 0 ||uci10.length === 0 ||uci11.length === 0 
            ||uci12.length === 0 ||uci13.length === 0 ||uci14.length === 0) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            });
         } else {
            db.collection('Uci Intermedio adultos').add({
                user: currentUser,
                currentDate: currentDay,
                date: date,
                uci1: uci1,
                uci2: uci2,
                uci3: uci3,
                uci4: uci4,
                uci5: uci5,
                uci6: uci6,
                uci7: uci7,
                uci8: uci8,
                uci9: uci9,
                uci10: uci10,
                uci11: uci11,
                uci12: uci12,
                uci13: uci13,
                uci14: uci14
            })
            .then(() => {
               swal({
                   title: '¡Exito!',
                   text: 'Los datos han sido enviados exitosamente',
                   icon: 'success',
                   button: 'Seguir'
               });
            })
            .then(() => {
               history.goBack();
            })
            .catch((error) => {
               console.log(error)
            })
         }
         
    }     
    
    return (
        <div className='form' onSubmit={handleSubmit}>
            <Topbar/>
            <div className="form--container">
                <h2>UCI Intermedio Adultos</h2>
                <div className='form--container__form'>
                    <form action="">
                        <MonthsInput
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                        {
                            data.map((data => 
                                <Input 
                                    key={data.key}
                                    type={data.type}
                                    label={data.label} 
                                    placeholder={data.placeholder}
                                    name={data.name}
                                    onChange={data.onChange}    
                                />
                                ))
                        }
                        <div className="form--container__btn">
                            <Button
                                title='Enviar'
                            /> 
                        </div>            
                    </form>
                </div>
            </div>
            <Navbar/>   
        </div>
    )
}

export default Uci
