import React from 'react';
import './Card.scss';



function Card({ icon,name, aa }) {
    return (
     
            <div className='card'>
                <div className='card--container'>
                    {icon}
                </div>
                <div className="card--container__info">
                    <p>{name}</p>
                </div>     
            </div>
    )
}

export default Card
