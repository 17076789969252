import firebase from 'firebase';
import 'firebase/auth'
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCwT1Sxd_hoAjrrq0OqIFjBuTMgPInx8u0",
  authDomain: "app-cencardio.firebaseapp.com",
  projectId: "app-cencardio",
  storageBucket: "app-cencardio.appspot.com",
  messagingSenderId: "376117522105",
  appId: "1:376117522105:web:42c56415b74b8426563568",
  measurementId: "G-YQMTL6HMK3"
  };
  // Initialize Firebase
  export const app = firebase.initializeApp(firebaseConfig);
  export const db = app.firestore();
