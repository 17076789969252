import React from 'react';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import { BiHomeAlt, BiSupport, BiUser } from "react-icons/bi";

function Navbar() {
    return (
        <div className='navbar--container'>
            <Link to='/dashboard' className='navbar--container__list'>
                <i className='navbar--container__list-icon'><BiHomeAlt size='24px' /></i>
                <span className='navbar--container__list-text'>Home</span>
            </Link>
            <Link to='/soporte' className='navbar--container__list'>
                <i><BiSupport size='24px' /></i>
                <span>Soporte</span>
            </Link>
            <Link to='/perfil' className='navbar--container__list'>
                <i><BiUser size='24px' /></i>
                <span>Perfil</span>
            </Link>  
        </div>
    )
}

export default Navbar
