import React, { useState } from 'react';
import './Seguimiento.scss'; 
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { app, db } from '../../../firebase/firebaseConfig';
import Topbar from '../../../components/Topbar/Topbar';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Input from '../../../components/Input/Input';
import Navbar from '../../../components/Navbar/Navbar';
import Button from '../../../components/Button/Button';

function Seguimiento() { 

    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    const history = useHistory()

        //set state for data
        const [name, setName] = useState('');
        const [register, setRegister] = useState('');
        const [age, setAge] = useState('');
        const [idType, setIdType] = useState('');
        const [id, setId] = useState('');
        const [bed, setBed] = useState('');
        const [entryDate, setEntryDate] = useState('');
        const [diagnostic, setDiagnostic] = useState('');
        const [curacion1, setCuracion1] = useState('');
        const [observation1, setObservation1] = useState('');
        const [observation2, setObservation2] = useState('');
        const [observation3, setObservation3] = useState('');
        const [observation4, setObservation4] = useState('');

       //data
       const data = [
        {
            key: '1',
            label: 'Nombre completo del paciente',
            type: 'text',
            value: name,
            onChange: (e) => setName(e.target.value)
        },
        {
            key: '2',
            label: 'Registro',
            type: 'text',
            value: register,
            onChange: (e) => setRegister(e.target.value)
        },
        {
            key: '3',
            label: 'Edad',
            type: 'number',
            value: age,
            onChange: (e) => setAge(e.target.value)
        },
        {
            key: '3.5',
            label: 'Tipo de Documento',
            type: 'text',
            value: idType,
            onChange: (e) => setIdType(e.target.value)
        },
        {
            key: '4',
            label: 'Identificación',
            type: 'number',
            value: id,
            onChange: (e) => setId(e.target.value)
        },
        {
            key: '5',
            label: 'Cama',
            type: 'text',
            value: bed,
            onChange: (e) => setBed(e.target.value)
        },
        {
            key: '6',
            label: 'Fecha de ingreso',
            type: 'date',
            value: entryDate,
            onChange: (e) => setEntryDate(e.target.value)
        },
        {
            key: '7',
            label: 'Diagnóstico',
            type: 'text',
            value: diagnostic,
            onChange: (e) => setDiagnostic(e.target.value)
        },
    ]

    const handleSubmit = (e) => {
        e.preventDefault(e);
        if(name.length === 0 || register.length === 0 || age.length === 0 || idType.length === 0 || 
            id.length === 0 || bed.length === 0 || entryDate.length === 0 || diagnostic.length === 0 || 
            curacion1.length === 0 || observation1.length === 0 || observation2.length === 0 || 
             observation3.length === 0 || observation4.length === 0  ) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos.',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('Seguimiento de las condiciones de acceso venoso').add({
                user: currentUser,
                currentDate: currentDate,
                name: name,
                register: register,
                age: age,
                idType: idType,
                id: id,
                bed: bed,
                entryDate: entryDate,
                diagnostic: diagnostic,
                curacion1: curacion1,
                observation1: observation1,
                observation2: observation2,
                observation3: observation3,
                observation4: observation4,
            })
            .then(()=> {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(()=> {
                history.goBack();
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

    return (
        <div className='seguimiento'>
            <Topbar/>
                <h2>Seguimiento de las condiciones de acceso venoso</h2>
            <div className="seguimiento--container">
                <h2>Identificación del paciente</h2>
                <form onSubmit={handleSubmit}>
                {
                        data.map(data => 
                            <Input
                                key={data.key}
                                label={data.label}
                                type={data.type}
                                value={data.value}
                                onChange={data.onChange}
                            />
                            )
                    }
                <h2>Seguimiento de las condiciones de acceso venoso</h2>
                <h2>Curación</h2>
                <SelectInput
                    label='Secreción / Sangre'
                    value={curacion1}
                    onChange={(e)=>setCuracion1(e.target.value)}
                />
                <h2>Observaciones</h2>
                <SelectInput
                    label='Eritema'
                    value={observation1}
                    onChange={(e)=>setObservation1(e.target.value)}
                /> 
                <SelectInput
                    label='Bordes Levantados'
                    value={observation2}
                    onChange={(e)=>setObservation2(e.target.value)}
                />
                <SelectInput
                    label='Exposición del sitio de inserción'
                    value={observation3}
                    onChange={(e)=>setObservation3(e.target.value)}
                />
                <SelectInput
                    label='Criterios de Evaluación / Flebitis'
                    value={observation4}
                    onChange={(e)=>setObservation4(e.target.value)}
                />
                <div className="seguimiento--container__btn">
                    <Button 
                        title='Enviar'
                    />
                </div>
                </form>
            </div>
            <Navbar/>
        </div>
    )
}

export default Seguimiento
