import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from './UserProvider';

const PrivateRoute = ( { component: RouteComponent, ...rest } ) => {
    const  currentUser  = useContext(UserContext);
    return (
        <Route
        {...rest}
        render={routerProps => 
            !!currentUser ? (
                <RouteComponent {...routerProps} />
            ) : (
                <Redirect to={'/'} />
            )
        }
        />

        
    )
}

export default PrivateRoute