import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Covid.scss';
import { app, db } from '../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../components/Topbar/Topbar';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Navbar from '../../components/Navbar/Navbar';

function Covid() {

    //Get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //useHistory Hook
    const history = useHistory();

    //data to get captured
    const [date, setDate] = useState('');
    const [suspects, setSuspects] = useState('');
    const [confirmed, setConfirmed] = useState('');
    const [deaths, setDeaths] = useState('');
    const [recoveries, setRecoveries] = useState('');
    
    //data for input fields
    const data = [
        {
            key: '1',
            label: 'Fecha',
            type: 'date',
            value: date,
            onChange: function (e) 
            { 
                return setDate(e.target.value) 
            }
        },
        {
            key: '2',
            label: 'Casos Sospechosos',
            type: 'number',
            value: suspects,
            onChange: function (e) 
            { 
                return setSuspects(e.target.value) 
            }
        },
        {
            key: '3',
            label: 'Casos Confirmados',
            type: 'number',
            value: confirmed,
            onChange: function (e) 
            { 
                return setConfirmed(e.target.value) 
            }
            
        },
    
        {
            key: '4',
            label: 'Fallecidos',
            type: 'number',
            value: deaths,
            onChange: function (e) 
            { 
                return setDeaths(e.target.value) 
            }
            
        },
    
        {
            key: '5',
            label: 'Recuperados',
            type: 'number',
            value: recoveries,
            onChange: function (e) 
            { 
                return setRecoveries(e.target.value) 
            }
            
        },
    ]

    // send data to firestore
    const handleSubmit = (e) => {
        e.preventDefault();
        if (date.length === 0 || suspects.length === 0 
            || confirmed.length === 0 || deaths.length === 0 
            || recoveries.length === 0) {
            swal({
                title: 'Cuidado',
                text: 'Uno o mas campos incompletos, revisa que todos los campos esten llenos',
                icon: 'warning',
                button: 'Volver'
            });
        } else {
            db.collection('Covid').add({
                user: currentUser,
                Currentdate: currentDate,
                date: date,
                suspects: suspects,
                confirmed: confirmed,
                deaths: deaths,
                recoveries: recoveries
            })
            //success!
            .then(()=> {
                swal({
                    title: '¡Exito!',
                    text: 'Los datos han sido enviados exitosamente',
                    icon: 'success',
                    button: 'Seguir'
                });
            })
            .then(() => {
                //clear input fields
                setDate('');
                setSuspects('');
                setConfirmed('');
                setDeaths('');
                setDeaths('');
                setRecoveries('');
            })
            .then(function () {
                history.goBack();
           })
            .catch((error) => {
                console.log(error)
            })
        } 
    }
    return (
        <div className='covid'>
            <Topbar/>
            <div className="covid--container">
                <h2>Covid-19</h2>
            <div className="covid--container__input">
                <form action="" onSubmit={handleSubmit}>
                    {
                        data.map(data => 
                            <Input 
                                key={data.key}
                                label={data.label}
                                type={data.type}
                                value={data.value}
                                onChange={data.onChange}
                            />   
                            )
                    }

                    <div className="covid--container__btn">
                        <Button 
                            title='Enviar'
                        />
                    </div>
                </form>
            </div>
            <Navbar/>
            </div>
            
        </div>
    )
}

export default Covid
