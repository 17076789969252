import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import './MedidasAislamiento.scss';
import { app, db } from '../../../firebase/firebaseConfig';
import swal from 'sweetalert';
import Topbar from '../../../components/Topbar/Topbar';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Navbar from '../../../components/Navbar/Navbar';


function MedidasAislamiento() {
    // get current user and date
    const currentUser = app.auth().currentUser.email;
    const currentDate = new Date();
    //useHistory Hook
    const history = useHistory();

    //set state for data
    const [date, setDate] = useState('');
    const [type, setType] = useState('');
    const [activity, setActivity] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [option4, setOption4] = useState('');
    const [option5, setOption5] = useState('');
    const [option6, setOption6] = useState('');
    const [option7, setOption7] = useState('');
    const [option8, setOption8] = useState('');
    const [option9, setOption9] = useState('');
    const [option10, setOption10] = useState('');
    const [option11, setOption11] = useState('');




    // date
    const data = [
        {
            key: '1',
            label: 'Fecha',
            type: 'date',
            value: date,
            onChange: (e) => setDate(e.target.value),
        },
        {
            key: '2',
            label: 'Tipo de aislamiento',
            type: 'text',
            value: type,
            onChange: (e) => setType(e.target.value),
        },
        {
            key: '3',
            label: 'Actividades/Habitación',
            type: 'text',
            value: activity,
            onChange: (e) => setActivity(e.target.value)
        },
    ]

    const education = [
        {
            key: '1',
            label:'1. ¿El paciente recibe instrucciones precisas sobre condiciones de aislamiento?',
            value: option1,
            onChange: (e) => setOption1(e.target.value)

        },
        {
            key: '2',
            label:'2. ¿El familiar recibe instrucciones precisas sobre condiciones de aislamiento?',
            value: option2,
            onChange: (e) => setOption2(e.target.value)
        },
    ]

    const knowledge = [
        {
            key: '1',
            label: '3. ¿Conoce en el Servicio la Guía de Precauciones para el Aislamiento de Pacientes?',
            value: option3,
            onChange: (e) => setOption3(e.target.value)
        }
    ]

    const practices = [
        {
            key: '1',
            label: '4. ¿Utiliza los elementos necesarios para el Aislamiento del paciente? Contato (bata y guantes en la hab), Aereo (tapabocas N95) y Gotas (tapabocas convencional)',
            value: option4,
            onChange: (e) => setOption4(e.target.value)
        },
        {
            key: '2',
            label: '5. ¿Hay dentro de la habitacion la dotación de los elementos de protección personal en el servicio?',
            value: option5,
            onChange: (e) => setOption5(e.target.value)
        },
        {
            key: '3',
            label: '6. ¿Se realiza lavado de manos antes y después del ingreso a la habitación del paciente?',
            value: option6,
            onChange: (e) => setOption6(e.target.value)
        },
        {
            key: '4',
            label: '7. ¿Mantiene la puerta cerrada de la habitación?',
            value: option7,
            onChange: (e) => setOption7(e.target.value)
        },
        {
            key: '5',
            label: '8. ¿Hay manejo adecuado de la ropa del paciente?',
            value: option8,
            onChange: (e) => setOption8(e.target.value)
        },
        {
            key: '6',
            label: '9. ¿Hay manejo adecuado de los residuos en la habitación del paciente?',
            value: option9,
            onChange: (e) => setOption9(e.target.value)
        },
    ]

    const patients = [
        {
            key: '1',
            label: '10. ¿Cuando el paciente se traslada cumple con las Precauciones de aislamiento?',
            value: option10,
            onChange: (e) => setOption10(e.target.value)
        },
        {
            key: '2',
            label: '11. ¿Informa al servicio del traslado las precauciones de aislamiento que tiene este paciente?',
            value: option11,
            onChange: (e) => setOption11(e.target.value)
        },
    ]

    //send data to firestore
    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('Medidas de Aislamiento').add({
            currentUser: currentUser,
            currentDate: currentDate,
            date: date,
            type: type,
            activity: activity,
            option1: option1,
            option2: option2,
            option3: option3,
            option4: option4,
            option5: option5,
            option6: option6,
            option7: option7,
            option8: option8,
            option9: option9,
            option10: option10,
            option11: option11,
        })
        .then(() => {
            swal({
                title: '¡Exito!',
                text: 'Los datos han sido enviados exitosamente',
                icon: 'success',
                button: 'Seguir'
            });
        })
        .then(function () {
            history.goBack();
       })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <div className='list'>
            <Topbar/>
            <div className="list--container">
                <h2>Medidas de Aislamiento</h2>
                <div className="list--container__form">
                    <form action="" onSubmit={handleSubmit}>
                        {
                            data.map(data => 
                                    <Input 
                                        key={data.key}
                                        label={data.label}
                                        type={data.type}
                                        value={data.value}
                                        onChange={data.onChange}
                                    /> 
                                )
                        }
                        <h3>Educación</h3>
                        {
                            education.map(education => 
                                    <SelectInput
                                        key={education.key}
                                        label={education.label}
                                        value={education.value}
                                        onChange={education.onChange}
                                    />  
                                )
                        }
                        <h3>Conocimiento</h3>
                        {
                            knowledge.map(knowledge => 
                                    <SelectInput
                                        key={knowledge.key}
                                        label={knowledge.label}
                                        value={knowledge.value}
                                        onChange={knowledge.onChange}
                                    />  
                                )
                        }
                        <h3>Buenas Prácticas</h3>
                        {
                            practices.map(practices => 
                                    <SelectInput
                                        key={practices.key}
                                        label={practices.label}
                                        value={practices.value}
                                        onChange={practices.onChange}
                                    />  
                                )
                        }
                        <h3>Traslado de Pacientes</h3>
                        {
                            patients.map(patients => 
                                    <SelectInput
                                        key={patients.key}
                                        label={patients.label}
                                        value={patients.value}
                                        onChange={patients.onChange}
                                    />  
                                )
                        }
                        <div className="list--container__btn">
                            <Button
                                title='Enviar'
                            />
                        </div>
                    </form>
                </div>
                <Navbar/>
            </div>
        </div>
    )
}

export default MedidasAislamiento
